import React, { ComponentType, useMemo } from 'react';
import { EStartMenuTab } from '../../store/menuSlice';
import { UiConfig } from '../../store/reprovisynApi';

export interface ITab {
  id: EStartMenuTab;
  Tab: ComponentType;
  name: string;
}

// Lazy load the InsightsTab to avoid loading the insight management code when it's not needed
const LazyDatasetTab = React.lazy(() => import('../../menu/DatasetsTab').then((module) => ({ default: module.DatasetsTab })));
const LazyInsightsTab = React.lazy(() => import('../../insight_integration/InsightsTab').then((module) => ({ default: module.InsightsTab })));
const LazyAnalysisSessionsTab = React.lazy(() =>
  import('../../menu/analysis-sessions/AnalysisSessionsTab').then((module) => ({ default: module.AnalysisSessionsTab })),
);

export function useTabs({ appConfig }: { appConfig: UiConfig }): ITab[] {
  const tabsList = useMemo(() => {
    if (!appConfig) {
      return [];
    }

    return [
      { id: EStartMenuTab.DATASET, name: 'reprovisyn:menu.datasets', Tab: React.memo(LazyDatasetTab) },

      ...(appConfig?.featureEnableSessions
        ? [{ id: EStartMenuTab.ANALYSIS_SESSIONS, name: 'reprovisyn:menu.analysisSessions', Tab: React.memo(LazyAnalysisSessionsTab) }]
        : []),

      ...(appConfig?.featureEnableInsightManagement
        ? [{ id: EStartMenuTab.CAMPAIGNS, name: 'reprovisyn:menu.campaigns', Tab: React.memo(LazyInsightsTab) }]
        : []),
    ];
  }, [appConfig]);

  return tabsList;
}
