import * as React from 'react';
import { cx, css } from '@emotion/css';
import { Text, Image, Flex, Grid, Stack, Group, Anchor } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import datavisynLogo from '../../assets/logos/datavisyn.svg';
import jkuLogo from '../../assets/logos/johannes-kepler-university-linz.svg';
import boehringerIngelheimLogo from '../../assets/logos/boehringer-ingelheim.svg';
import { WELCOME_PAGE_BREAKPOINT_SMALL } from '../constants';

export function DevelopedByAffiliations() {
  const largerThanSmallScreenSize = useMediaQuery(`(min-width: ${WELCOME_PAGE_BREAKPOINT_SMALL})`);

  return (
    <Grid
      w="100%"
      justify="center"
      align="baseline"
      my="xl"
      className={cx(css`
        .mantine-Grid-inner {
          max-width: 100%;
        }
      `)}
    >
      <Grid.Col span={largerThanSmallScreenSize ? 5 : 12}>
        <Stack w="100%" align="center">
          <Text c="gray.6" size="md">
            Ordino is developed and maintained by
          </Text>
          <Group align="center">
            <Anchor href="https://datavisyn.io" target="_blank" rel="noopener noreferrer">
              <Image src={datavisynLogo} fit="contain" h="35px" />
            </Anchor>
          </Group>
        </Stack>
      </Grid.Col>
      <Grid.Col span={largerThanSmallScreenSize ? 5 : 12} mt={largerThanSmallScreenSize ? 'inherit' : 'xl'}>
        <Stack w="100%" align="center">
          <Text c="gray.6" size="md" ta="center">
            The foundations of Ordino were developed in collaboration with
          </Text>
          <Flex align="center" w="100%" justify="space-evenly">
            <Anchor href="https://www.jku.at/" target="_blank" rel="noopener noreferrer">
              <Image src={jkuLogo} fit="contain" h="45px" />
            </Anchor>
            <Anchor href="https://www.boehringer-ingelheim.com/" target="_blank" rel="noopener noreferrer">
              <Image src={boehringerIngelheimLogo} fit="contain" h="45px" />
            </Anchor>
          </Flex>
        </Stack>
      </Grid.Col>
    </Grid>
  );
}
