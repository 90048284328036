import { CellSchema, Persister as TinybasePersister, Store as TinybaseStore, createRelationships, createStore as createTinybaseStore } from 'tinybase';
import { createIndexedDbPersister } from 'tinybase/persisters/persister-indexed-db';
import { LocalAnalysisSnapshot, TinybaseStoreKeys } from './analysis-sessions.types';
import { AnalysisSession } from '../visynApi';

export const analysisSessionStore = createTinybaseStore().setTablesSchema({
  [TinybaseStoreKeys.AnalysisSessions]: {
    id: { type: 'string' },
    name: { type: 'string' },
    changedBy: { type: 'string' },
    changedOn: { type: 'string' },
    createdBy: { type: 'string' },
    createdOn: { type: 'string' },
    lastOpenedOn: { type: 'number' },
    /**
     * This property would infer type `ArrayAsString` in its implementation
     */
    collabs: { type: 'string' },
    description: { type: 'string' },
    public: { type: 'boolean' },
    selectedSnapshot: { type: 'string' }, // currently selected snapshot, readonly
    currentSnapshot: { type: 'string' }, // currently snapshot, currently being edited
    appName: { type: 'string' },
    isSharedSession: { type: 'boolean' },
    visibility: { type: 'string' },
  } as Record<keyof Omit<AnalysisSession, 'currentSnapshot' | 'snapshots'>, CellSchema>,

  [TinybaseStoreKeys.StateSnapshots]: {
    id: { type: 'string' },
    sessionId: { type: 'string' },
    snapshot: { type: 'string' },
    name: { type: 'string' },
    appName: { type: 'string' },
    appVersion: { type: 'string' },
    changedBy: { type: 'string' },
    changedOn: { type: 'string' },
    createdBy: { type: 'string' },
    createdOn: { type: 'string' },
    description: { type: 'string' },
  } as Record<keyof LocalAnalysisSnapshot, CellSchema>,
});

export const relationships = createRelationships(analysisSessionStore);
relationships.setRelationshipDefinition(
  TinybaseStoreKeys.SessionStateSnapshots,
  TinybaseStoreKeys.StateSnapshots,
  TinybaseStoreKeys.AnalysisSessions,
  'sessionId',
);

export const tinybaseIndexedDbPersisterFactory = (store: TinybaseStore) => {
  /**
   * How often to poll the database when auto-loading is enabled
   * The default value is 1 seconds which causes some data loss when multiple sessions are opened in different tabs in quick succession.
   * The tab that is opened last will delete the data of the previous tab in the indexedDB.
   * This is because the existing data of the first tab is not loaded in time to be saved.
   * This value is set to 0.5 seconds to alleviate the data loss.
   * TODO: we need to check and fix the tinybase hooks to load the current session in the SessionsContext.
   */
  const autoLoadIntervalSeconds: number = 0.5;

  return createIndexedDbPersister(store, TinybaseStoreKeys.AnalysisSessions, autoLoadIntervalSeconds);
};

export const tinybasePersisterJob = async (persister: TinybasePersister) => {
  await persister.load();
  await persister.startAutoSave();
};
