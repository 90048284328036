import { IOrdinoAppState } from '../interfaces';
import createTransform from '../persist/createTransform';

export const workbenchesPersistTransform = createTransform<IOrdinoAppState[keyof IOrdinoAppState]>(
  (inboundState, key) => {
    // Ignore the workbench data when saving the state
    if (key === 'workbenches') {
      const v = (inboundState as IOrdinoAppState['workbenches']).map((workbench) => ({
        ...workbench,
        data: [],
        dataMap: {},
        isLoading: false,
        dataLength: null,
        openSidebar: null,
      }));

      return v;
    }
    return inboundState;
  },
  (outboundState, key) => {
    // Do the same filtering on the outbound state
    if (key === 'workbenches') {
      return (outboundState as IOrdinoAppState['workbenches']).map((workbench) => ({
        ...workbench,
        isLoading: false,
        // filter out the selection adapter columns that might have been saved in an older session. All new sessions do not use this property anymore
        columnDescs: workbench.columnDescs.filter((columnDesc) => !columnDesc.isSelectionAdapterColumn),
      }));
    }
    return outboundState;
  },
  { whitelist: ['workbenches'] },
);
