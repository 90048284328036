import { Alert, Text } from '@mantine/core';
import * as React from 'react';

export function WarningMessage({ warning, dataTestId = 'ordino-warning-message' }: { warning: string | null; dataTestId?: string }) {
  return warning ? (
    <Alert px="md" icon={<i className="fa-solid fa-circle-exclamation" />} color="yellow" data-testid={dataTestId}>
      <Text>{warning}</Text>
    </Alert>
  ) : null;
}
