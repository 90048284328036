import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Group, ScrollArea, Spoiler, Stack, Text } from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { i18n } from 'visyn_core/i18n';
import { pluginRegistry } from 'visyn_core/plugin';
import { WorkbenchUtils } from '../app/WorkbenchUtils';
import { EStartMenuMode, setActiveTab, setMode } from './menuSlice';
import { addFirstWorkbench } from './ordinoSlice';
import { EntityMeta, reprovisynApi } from './reprovisynApi';
import { UPLOAD_TAG } from './reprovisynBaseApi';
import { visynApi } from './visynApi';
import { ANALYSIS_SESSIONS_TAG, UPLOADED_FILES } from './visynBaseApi';

const apiEnhancements: Parameters<typeof visynApi.enhanceEndpoints>[0] = {
  addTagTypes: [UPLOAD_TAG],
  endpoints: {
    getUploadedFilesListMetadataApiFilesListMetadataGet: {
      providesTags: (result) => (result ? [{ type: UPLOADED_FILES, id: 'List' }] : []),
    },
    deleteUploadApiFilesUuidDelete: {
      invalidatesTags: () => [{ type: UPLOADED_FILES, id: 'List' }],
      onQueryStarted: async (args, { queryFulfilled }) => {
        const notificationId = uuidv4();
        showNotification({
          id: notificationId,
          loading: true,
          color: 'dvGray',
          title: (
            <Text data-testid="ordino-delete-uploaded-file-notification-progress">
              {i18n.t('reprovisyn:notifications.deletingWithTypeWithName', {
                item: args.uuid,
                type: i18n.t('reprovisyn:datasets.file'),
                interpolation: { escapeValue: false },
              })}
            </Text>
          ),
          message: '',
          autoClose: false,
          withCloseButton: false,
        });
        queryFulfilled
          .then((data) =>
            updateNotification({
              id: notificationId,
              loading: false,
              icon: <FontAwesomeIcon icon={faCheck} />,
              color: 'teal',
              title: (
                <Text data-testid="ordino-delete-uploaded-file-notification-success">
                  {i18n.t('reprovisyn:notifications.deleteSuccess', {
                    type: i18n.t('reprovisyn:datasets.file'),
                    name: args.uuid,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            }),
          )
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              color: 'red',
              title: (
                <Text data-testid="ordino-delete-uploaded-file-error">
                  {i18n.t('reprovisyn:notifications.deleteError', {
                    type: i18n.t('reprovisyn:datasets.file'),
                    name: args.uuid,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: (
                <ScrollArea.Autosize mah={150}>
                  <Spoiler maxHeight={120} showLabel={i18n.t('reprovisyn:messages.showMore')} hideLabel={i18n.t('reprovisyn:messages.hide')}>
                    {error?.data.detail || error?.message}
                  </Spoiler>
                </ScrollArea.Autosize>
              ),
            });
          });
      },
    },
    getAnalysisSessionsListApiAnalysisSessionsListGet: {
      providesTags: (result) => (result ? [{ type: ANALYSIS_SESSIONS_TAG, id: 'List' }] : []),
    },
    getAnalysisSessionApiAnalysisSessionsIdGet: {
      providesTags: (result, error, { id }) => [{ type: ANALYSIS_SESSIONS_TAG, id }],
    },
    updateAnalysisSessionApiAnalysisSessionsIdPut: {
      invalidatesTags: (result, error, { id }) => [
        { type: ANALYSIS_SESSIONS_TAG, id },
        { type: ANALYSIS_SESSIONS_TAG, id: 'List' },
      ],
      onQueryStarted: async (args, { queryFulfilled }) => {
        const notificationId = uuidv4();

        showNotification({
          id: notificationId,
          loading: true,
          color: 'gray',
          title: (
            <Text>
              {i18n.t('reprovisyn:notifications.saving', {
                type: 'session',
                name: args.analysisSessionUpdate.name,
                interpolation: { escapeValue: false },
              })}
            </Text>
          ),
          message: '',
          autoClose: false,
          withCloseButton: false,
        });

        queryFulfilled
          .then((data) =>
            updateNotification({
              id: notificationId,
              loading: false,
              icon: <FontAwesomeIcon icon={faCheck} />,
              color: 'teal',
              title: (
                <Text>
                  {i18n.t('reprovisyn:notifications.save', {
                    type: 'Session',
                    name: args.analysisSessionUpdate.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            }),
          )
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              mah: 500,
              color: 'red',
              title: (
                <Text>
                  {i18n.t('reprovisyn:notifications.errorSaving', {
                    type: 'session',
                    name: args?.analysisSessionUpdate.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: (
                <ScrollArea.Autosize mah={150}>
                  <Spoiler maxHeight={100} showLabel={i18n.t('reprovisyn:messages.showMore')} hideLabel={i18n.t('reprovisyn:messages.hide')}>
                    {error?.data.detail || error?.message}
                  </Spoiler>
                </ScrollArea.Autosize>
              ),
              autoClose: false,
            });
          });
      },
    },
    createAnalysisSessionApiAnalysisSessionsCreatePost: {
      invalidatesTags: [{ type: ANALYSIS_SESSIONS_TAG, id: 'List' }],
      onQueryStarted: async (args, { queryFulfilled }) => {
        const notificationId = uuidv4();
        showNotification({
          id: notificationId,
          loading: true,
          color: 'gray',
          title: (
            <Text>
              {i18n.t('reprovisyn:notifications.saving', {
                type: 'session',
                name: args.analysisSessionCreate.name,
                interpolation: { escapeValue: false },
              })}
            </Text>
          ),
          message: '',
          autoClose: false,
          withCloseButton: false,
        });
        queryFulfilled
          .then((data) =>
            updateNotification({
              id: notificationId,
              loading: false,
              icon: <FontAwesomeIcon icon={faCheck} />,
              color: 'teal',
              title: (
                <Text>
                  {i18n.t('reprovisyn:notifications.save', {
                    type: 'Session',
                    name: args.analysisSessionCreate.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            }),
          )
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              mah: 500,
              color: 'red',
              title: (
                <Text>
                  {i18n.t('reprovisyn:notifications.errorSaving', {
                    type: 'session',
                    name: args?.analysisSessionCreate.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: (
                <ScrollArea.Autosize mah={150}>
                  <Spoiler maxHeight={100} showLabel={i18n.t('reprovisyn:messages.showMore')} hideLabel={i18n.t('reprovisyn:messages.hide')}>
                    {error?.data.detail || error?.message}
                  </Spoiler>
                </ScrollArea.Autosize>
              ),
              autoClose: false,
            });
          });
      },
    },
    deleteAnalysisSessionApiAnalysisSessionsIdDelete: {
      invalidatesTags: [{ type: ANALYSIS_SESSIONS_TAG, id: 'List' }],
      onQueryStarted: async (args, { queryFulfilled }) => {
        const notificationId = uuidv4();
        showNotification({
          id: notificationId,
          loading: true,
          color: 'gray',
          title: (
            <Text>
              {i18n.t('reprovisyn:notifications.deletingWithTypeWithName', { type: 'session', item: args.id, interpolation: { escapeValue: false } })}
            </Text>
          ),
          message: '',
          autoClose: false,
          withCloseButton: false,
        });
        queryFulfilled
          .then((data) =>
            updateNotification({
              id: notificationId,
              loading: false,
              icon: <FontAwesomeIcon icon={faCheck} />,
              color: 'teal',
              title: (
                <Text>
                  {i18n.t('reprovisyn:notifications.deleteSuccess', {
                    type: 'Session',
                    name: args.id,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            }),
          )
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              mah: 500,
              color: 'red',
              title: (
                <Text>
                  {i18n.t('reprovisyn:notifications.deleteError', {
                    type: 'session',
                    name: args.id,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: (
                <ScrollArea.Autosize mah={150}>
                  <Spoiler maxHeight={100} showLabel={i18n.t('reprovisyn:messages.showMore')} hideLabel={i18n.t('reprovisyn:messages.hide')}>
                    {error?.data.detail || error?.message}
                  </Spoiler>
                </ScrollArea.Autosize>
              ),
              autoClose: false,
            });
          });
      },
    },
    entityPostApiUploadUuidPost: {
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const onOpenEntity = (entity: EntityMeta) => {
          const startingWorkbench = WorkbenchUtils.firstWorkbenchConfig({
            entity,
            queryParams: {
              globalQuery: { name: '', appliedCategories: [], availableCategories: [] },
            },
            isUploaded: true,
          });
          dispatch(addFirstWorkbench(startingWorkbench));
          dispatch(setActiveTab(null));
          dispatch(setMode(EStartMenuMode.OVERLAY));
        };

        const id = showNotification({
          autoClose: false,
          color: 'gray',
          loading: true,
          withCloseButton: true,
          message: (
            <Stack gap="xs">
              <Text data-testid="ordino-dataset-create-notification-progress">
                {i18n.t('reprovisyn:datasets.uploadingAndConfiguringEntity', {
                  entityName: args.uploadedEntityCreate.name,
                  interpolation: { escapeValue: false },
                })}
              </Text>
              <Text>{i18n.t('reprovisyn:datasets.uploadingInfo')}</Text>
            </Stack>
          ),
        });

        queryFulfilled
          .then(({ data: entity }) => {
            dispatch(reprovisynApi.util.invalidateTags([UPLOAD_TAG]));
            return updateNotification({
              id,
              autoClose: 5000,
              color: 'teal',
              icon: <FontAwesomeIcon icon={faCheck} />,
              loading: false,
              withCloseButton: true,
              closeButtonProps: { 'data-testid': 'ordino-dataset-create-notification-close-button' },
              message: (
                <Stack gap="xs">
                  <Group p="xs" justify="space-between">
                    <Text data-testid="ordino-dataset-create-notification-success">
                      {i18n.t('reprovisyn:datasets.successfullyConfigured', {
                        entityName: args.uploadedEntityCreate.name,
                        interpolation: { escapeValue: false },
                      })}
                    </Text>
                    <Group justify="flex-end">
                      <Button data-testid="ordino-dataset-create-notification-open-entity" onClick={() => onOpenEntity(entity)} variant="subtle">
                        {i18n.t('reprovisyn:action.open')}
                      </Button>
                    </Group>
                  </Group>
                </Stack>
              ),
            });
          })
          .catch(({ error }) => {
            updateNotification({
              id,
              autoClose: false,
              color: 'red',
              loading: false,
              withCloseButton: true,
              title: (
                <Text data-testid="ordino-dataset-create-notification-error">
                  {i18n.t('reprovisyn:reprovisynError.loadingError', { reason: error?.message })}
                </Text>
              ),
              message: error?.message,
            });
          });
      },
      invalidatesTags: [{ type: UPLOAD_TAG, id: 'List' }],
    },
    entityPutApiUploadUuidPut: {
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(reprovisynApi.util.invalidateTags([UPLOAD_TAG]));
        });
      },
      invalidatesTags: [{ type: UPLOAD_TAG, id: 'List' }],
    },
    entityDeleteApiUploadUuidDelete: {
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const notificationId = uuidv4();
        showNotification({
          id: notificationId,
          loading: true,
          color: 'gray',
          title: (
            <Text data-testid="ordino-delete-uploaded-dataset-notification-progress">
              {i18n.t('reprovisyn:notifications.deletingWithType', { type: i18n.t('reprovisyn:datasets.dataset'), interpolation: { escapeValue: false } })}
            </Text>
          ),
          message: '',
          autoClose: false,
          withCloseButton: false,
        });
        queryFulfilled
          .then(({ data: entityMeta }) => {
            dispatch(reprovisynApi.util.invalidateTags([UPLOAD_TAG]));
            pluginRegistry.removePlugins((desc) => desc.id.includes(args.uuid));
            updateNotification({
              id: notificationId,
              loading: false,
              icon: <FontAwesomeIcon icon={faCheck} />,
              color: 'teal',
              title: (
                <Text data-testid="ordino-delete-uploaded-dataset-notification-success">
                  {i18n.t('reprovisyn:notifications.deleteSuccess', {
                    type: i18n.t('reprovisyn:datasets.dataset'),
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            });
          })
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              color: 'red',
              title: (
                <Text data-testid="ordino-delete-uploaded-dataset-notification-error">
                  {i18n.t('reprovisyn:notifications.deleteError', {
                    type: i18n.t('reprovisyn:datasets.dataset'),
                    name: args.uuid,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: error?.data.detail || error?.message,
            });
          });
      },
      invalidatesTags: [{ type: UPLOAD_TAG, id: 'List' }],
    },
  },
};

export function addVisynApiEnhancements() {
  [apiEnhancements].forEach((e) => visynApi.enhanceEndpoints(e));
}
