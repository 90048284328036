/**
 * Height of the navbar header on the welcome page
 */
export const WELCOME_PAGE_HEADER_HEIGHT = 60;

/**
 * Ordino yellow color from the logo
 */
export const ORDINO_YELLOW = '#ffc400';

/**
 * Glass background style for cards
 */
export const WELCOME_PAGE_CSS_GLASS_CARD = {
  background: 'rgba(255, 255, 255, 0.2)',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: 'calc(var(--paper-radius) * 2)',
};

/**
 * Breakpoint for medium screens on the welcome page
 */
export const WELCOME_PAGE_BREAKPOINT_MEDIUM = '1900px';
export const WELCOME_PAGE_BREAKPOINT_SMALL = '800px';
