import { format } from 'd3v7';

/**
 * Height of the header in pixels
 */
export const ORDINO_HEADER_HEIGHT = 40;

/**
 * Function used to format count values (e.g., counted rows from an entity)
 */
export const ORDINO_COUNT_FORMATTER = format(',.0f');

/**
 * Local storage key for show banner
 */
export const LS_SHOW_BANNER_KEY = 'ordino-show-banner';

/**
 * Local storage key for the cached banner info
 */
export const LS_BANNER_INFO_KEY = 'ordino-banner-info';

/**
 * Local storage key to show chevron onboarding
 */
export const LS_CHEVRON_SHOW_ONBOARDING_KEY = 'ordino-chevron-show-onboarding';

/**
 * Local storage key for the locked node positions in the data landscape
 */
export const LS_DATA_LANDSCAPE_LOCKED_NODE_POSITIONS_KEY = 'ordino-data-landscape-locked-node-positions';

/**
 * Local storage key for the locked sessions
 */
export const LS_LOCKED_SESSION_KEY = 'ordino-locked-sessions';

/**
 * Ordino yellow color from the logo
 */
export const ORDINO_YELLOW = '#ffc400';
