import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Container, Group, HoverCard, ScrollArea, Space, Stack, Text, rgba, useMantineTheme } from '@mantine/core';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { i18n } from 'visyn_core/i18n';

export function ErrorFallback({ error, resetErrorBoundary, resetError, style }: FallbackProps & { resetError?: () => void; style?: React.CSSProperties }) {
  return error ? (
    <>
      <Space h="md" />
      <Container size="md" px="md" style={{ ...style }}>
        <Alert px="md" icon={<i className="fa-solid fa-circle-exclamation" />} title={i18n.t('reprovisyn:app.somethingWentWrong')} color="red">
          <Text>{'message' in error ? error.message : null}</Text>
          {resetErrorBoundary ? (
            <Group justify="flex-end">
              <Button
                onClick={() => {
                  resetErrorBoundary?.();
                  resetError?.();
                }}
                color="red"
                variant="subtle"
              >
                &nbsp;{i18n.t('reprovisyn:app.tryAgain')}&nbsp;
              </Button>
            </Group>
          ) : null}
        </Alert>
      </Container>
    </>
  ) : null;
}

export function ErrorFallbackSlim({
  error,
  resetErrorBoundary,
  resetError,
  style,
  withHoverCard = false,
}: FallbackProps & { resetError?: () => void; style?: React.CSSProperties; withHoverCard?: boolean }) {
  const theme = useMantineTheme();
  return error ? (
    <HoverCard withinPortal withArrow disabled={!withHoverCard}>
      <HoverCard.Target>
        <Group bg={rgba(theme.colors.red[5], 0.1)} gap={4} w="100%" justify="space-between" align="center" pl="md" wrap="nowrap" style={style}>
          <Group gap={4} align="center" wrap="nowrap">
            <FontAwesomeIcon icon={faCircleExclamation} color="red" />
            <Text c="red" fw="bold" truncate>
              {i18n.t('reprovisyn:app.somethingWentWrong')}
            </Text>
          </Group>
          {resetErrorBoundary ? (
            <Button
              onClick={() => {
                resetErrorBoundary?.();
                resetError?.();
              }}
              color="red"
              variant="subtle"
            >
              {i18n.t('reprovisyn:app.tryAgain')}
            </Button>
          ) : null}
        </Group>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Stack mah={320}>
          <ScrollArea>
            <Text size="xs" c="red">
              {'message' in error ? error.message : null}
            </Text>
            <Text size="xs" c="red" style={{ whiteSpace: 'pre' }}>
              {'stack' in error ? error.stack : null}
            </Text>
          </ScrollArea>
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  ) : null;
}
