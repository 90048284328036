import { css, cx } from '@emotion/css';
import { Container, Card, Text } from '@mantine/core';
import React from 'react';
import { MarkdownText } from '../../components/MarkdownText';
import { useGetApiV1ReprovisynAppConfigCustomizationQuery } from '../../store';
import { WELCOME_PAGE_CSS_GLASS_CARD } from '../constants';

const CARD_BG_BACKGROUND_ALPHA = '30'; // in hex

export function WelcomeMessageBanner() {
  const { data: customization } = useGetApiV1ReprovisynAppConfigCustomizationQuery();

  return (
    customization?.welcomeMessage?.message?.length > 0 && (
      <Container mt="xl" size="lg">
        <Card
          p="xl"
          style={{ ...WELCOME_PAGE_CSS_GLASS_CARD, backgroundColor: `${customization.welcomeMessage.backgroundColor || '#337AB7'}${CARD_BG_BACKGROUND_ALPHA}` }}
          data-testid="ordino-welcome-message-banner"
        >
          <Text
            id="markdown-holder"
            data-testid="ordino-welcome-message-markdown"
            className={cx(css`
              & p {
                margin-top: 0;
                margin-bottom: 1rem;
              }
            `)}
            component="div"
          >
            <MarkdownText>{customization.welcomeMessage.message}</MarkdownText>
          </Text>
        </Card>
      </Container>
    )
  );
}
