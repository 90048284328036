import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import intersection from 'lodash/intersection';
import { IOrdinoAppState, IWorkbench } from './interfaces';
import { GlobalQuery } from './reprovisynApi';
import { viewsReducers } from './viewsReducer';
import { workbenchReducers } from './workbenchReducer';

const initialState: IOrdinoAppState = {
  workbenches: [],
  focusWorkbenchIndex: 0,
  midTransition: false,
  isAnimating: false,
  globalQuery: {
    name: '',
    availableCategories: [],
    defaultCategories: [],
  },
  currentSessionId: '',
};

const ordinoSlice = createSlice({
  name: 'ordino',
  initialState,
  reducers: {
    ...viewsReducers,
    ...workbenchReducers,
    // TODO in general: does it make sense to group the reducer functions (e.g., by workbench, views, ...)? or even create multiple variables that are spread-in here.
    addEntityFormatting(state, action: PayloadAction<{ workbenchIndex: number; formatting: IWorkbench['formatting'] }>) {
      const { workbenchIndex, formatting } = action.payload;
      state.workbenches[workbenchIndex].formatting = formatting;
    },
    changeFocus(state: IOrdinoAppState, action: PayloadAction<{ index: number }>) {
      state.focusWorkbenchIndex = action.payload.index;
      state.workbenches[action.payload.index].collapsed = false;
      state.midTransition = false;
      state.isAnimating = true;
    },
    setTransition(state, action: PayloadAction<boolean>) {
      state.midTransition = action.payload;
    },
    setAnimating(state, action: PayloadAction<boolean>) {
      state.isAnimating = action.payload;
    },
    setAppliedCategories(state, action: PayloadAction<{ appliedCategories: GlobalQuery['appliedCategories'] }>) {
      const includesCurrentFilter =
        state.globalQuery.appliedCategories?.length === intersection(state.globalQuery.appliedCategories, action.payload.appliedCategories)?.length; // if no filter is selected then the new data is a superset of the previous data

      if (!includesCurrentFilter) {
        // if the new filter does not include the previous, remove all workbenches except the first one
        if (state.workbenches.length > 1) {
          state.workbenches.length = 1;
          state.focusWorkbenchIndex = 0;
          state.midTransition = false;
          state.isAnimating = true;
        }
        state.workbenches[0].selection = []; // clear the selection
      }
      state.globalQuery.appliedCategories = action.payload.appliedCategories;
    },
    resetOrdinoStore: () => initialState,
    setCurrentSessionId(state, action: PayloadAction<string>) {
      state.currentSessionId = action.payload;
    },
    resetSession: (state) => {
      state.currentSessionId = '';
    },
  },
});

export const {
  addView,
  changeSelectedMappings,
  setDataProviderDump,
  setOpenSidebar,
  setCreateNextWorkbenchSidebarOpen,
  setViewParameters,
  setInitialVisConfig,
  createColumnDescs,
  addColumnDesc,
  removeColumnDesc,
  setTransitionColumns,
  removeView,
  replaceWorkbench,
  removeWorkbench,
  removeWorkbenchOnEntityDelete,
  setCollapsed,
  setMidTransition,
  addEntityFormatting,
  addSelection,
  addRankingFilter,
  setWorkbenchFilter,
  setWorkbenchNamedIdSet,
  setWorkbenchData,
  setWorkbenchDataLoading,
  changeFocus,
  addFirstWorkbench,
  addWorkbench,
  setWorkbenchDirection,
  setTransition,
  setAnimating,
  setAppliedCategories,
  resetOrdinoStore,
  setCurrentSessionId,
  resetSession,
} = ordinoSlice.actions;

export const ordinoReducer = ordinoSlice.reducer;
