import { Group, useMantineTheme } from '@mantine/core';
import { cx, css } from '@emotion/css';
import React from 'react';
import { i18n } from 'visyn_core/i18n';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { EStartMenuMode, EStartMenuTab, setActiveTab } from '../../store/menuSlice';
import { useTabs } from './useTabs';

function TabButton({ id, name, menuMode, active = false }: { id: EStartMenuTab; name: string; menuMode: EStartMenuMode; active?: boolean }) {
  const dispatch = useAppDispatch();
  const theme = useMantineTheme();

  return (
    <a
      style={{
        color: active ? theme.colors.dark[6] : theme.white,
        backgroundColor: active ? '#f1f2f4' : 'transparent',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        textDecoration: 'none',
        cursor: 'pointer',
        fontSize: theme.fontSizes.md,
      }}
      className={cx(css`
        &:hover {
          color: ${active ? theme.colors.dark[6] : theme.white} !important;
          background-color: ${active ? '#f1f2f4' : '#585f66'} !important;
        }
      `)}
      data-testid={`${name?.replace(/\s/g, '-').toLowerCase()}-tab`}
      onClick={() => dispatch(setActiveTab(menuMode === EStartMenuMode.OVERLAY && active ? null : id))}
    >
      {name}
    </a>
  );
}

export function TabGroup() {
  const appReady = useAppSelector((state) => state.app.ready);
  const appConfig = useAppSelector((state) => state.app.uiConfig);
  const tabs = useTabs({ appConfig });
  const activeTab = useAppSelector((state) => state.menu.activeTab);
  const menuMode = useAppSelector((state) => state.menu.mode);
  const appVersionInfo = useAppSelector((state) => state.app.version);
  const hasActiveLicense = appVersionInfo?.daysLeft > 0;

  return appReady && hasActiveLicense ? (
    <Group h="100%" gap={0}>
      {tabs.map((t) => (
        <TabButton key={t.id} id={t.id} name={i18n.t(t.name)} active={t.id === activeTab} menuMode={menuMode} />
      ))}
    </Group>
  ) : null;
}
