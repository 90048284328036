/**
 * A map of named colors and their corresponding hexadecimal values.
 * @type {Map<string, string>}
 */
const NAMED_COLORS = new Map<string, string>([
  ['aliceblue', '#f0f8ff'],
  ['antiquewhite', '#faebd7'],
  ['aqua', '#00ffff'],
  ['aquamarine', '#7fffd4'],
  ['azure', '#f0ffff'],
  ['beige', '#f5f5dc'],
  ['bisque', '#ffe4c4'],
  ['black', '#000000'],
  ['blanchedalmond', '#ffebcd'],
  ['blue', '#0000ff'],
  ['blueviolet', '#8a2be2'],
  ['brown', '#a52a2a'],
  ['burlywood', '#deb887'],
  ['cadetblue', '#5f9ea0'],
  ['chartreuse', '#7fff00'],
  ['chocolate', '#d2691e'],
  ['coral', '#ff7f50'],
  ['cornflowerblue', '#6495ed'],
  ['cornsilk', '#fff8dc'],
  ['crimson', '#dc143c'],
  ['cyan', '#00ffff'],
  ['darkblue', '#00008b'],
  ['darkcyan', '#008b8b'],
  ['darkgoldenrod', '#b8860b'],
  ['darkgray', '#a9a9a9'],
  ['darkgreen', '#006400'],
  ['darkkhaki', '#bdb76b'],
  ['darkmagenta', '#8b008b'],
  ['darkolivegreen', '#556b2f'],
  ['darkorange', '#ff8c00'],
  ['darkorchid', '#9932cc'],
  ['darkred', '#8b0000'],
  ['darksalmon', '#e9967a'],
  ['darkseagreen', '#8fbc8f'],
  ['darkslateblue', '#483d8b'],
  ['darkslategray', '#2f4f4f'],
  ['darkturquoise', '#00ced1'],
  ['darkviolet', '#9400d3'],
  ['deeppink', '#ff1493'],
  ['deepskyblue', '#00bfff'],
  ['dimgray', '#696969'],
  ['dodgerblue', '#1e90ff'],
  ['firebrick', '#b22222'],
  ['floralwhite', '#fffaf0'],
  ['forestgreen', '#228b22'],
  ['fuchsia', '#ff00ff'],
  ['gainsboro', '#dcdcdc'],
  ['ghostwhite', '#f8f8ff'],
  ['gold', '#ffd700'],
  ['goldenrod', '#daa520'],
  ['gray', '#808080'],
  ['green', '#008000'],
  ['greenyellow', '#adff2f'],
  ['honeydew', '#f0fff0'],
  ['hotpink', '#ff69b4'],
  ['indianred', '#cd5c5c'],
  ['indigo', '#4b0082'],
  ['ivory', '#fffff0'],
  ['khaki', '#f0e68c'],
  ['lavender', '#e6e6fa'],
  ['lavenderblush', '#fff0f5'],
  ['lawngreen', '#7cfc00'],
  ['lemonchiffon', '#fffacd'],
  ['lightblue', '#add8e6'],
  ['lightcoral', '#f08080'],
  ['lightcyan', '#e0ffff'],
  ['lightgoldenrodyellow', '#fafad2'],
  ['lightgrey', '#d3d3d3'],
  ['lightgreen', '#90ee90'],
  ['lightpink', '#ffb6c1'],
  ['lightsalmon', '#ffa07a'],
  ['lightseagreen', '#20b2aa'],
  ['lightskyblue', '#87cefa'],
  ['lightslategray', '#778899'],
  ['lightsteelblue', '#b0c4de'],
  ['lightyellow', '#ffffe0'],
  ['lime', '#00ff00'],
  ['limegreen', '#32cd32'],
  ['linen', '#faf0e6'],
  ['magenta', '#ff00ff'],
  ['maroon', '#800000'],
  ['mediumaquamarine', '#66cdaa'],
  ['mediumblue', '#0000cd'],
  ['mediumorchid', '#ba55d3'],
  ['mediumpurple', '#9370d8'],
  ['mediumseagreen', '#3cb371'],
  ['mediumslateblue', '#7b68ee'],
  ['mediumspringgreen', '#00fa9a'],
  ['mediumturquoise', '#48d1cc'],
  ['mediumvioletred', '#c71585'],
  ['midnightblue', '#191970'],
  ['mintcream', '#f5fffa'],
  ['mistyrose', '#ffe4e1'],
  ['moccasin', '#ffe4b5'],
  ['navajowhite', '#ffdead'],
  ['navy', '#000080'],
  ['oldlace', '#fdf5e6'],
  ['olive', '#808000'],
  ['olivedrab', '#6b8e23'],
  ['orange', '#ffa500'],
  ['orangered', '#ff4500'],
  ['orchid', '#da70d6'],
  ['palegoldenrod', '#eee8aa'],
  ['palegreen', '#98fb98'],
  ['paleturquoise', '#afeeee'],
  ['palevioletred', '#d87093'],
  ['papayawhip', '#ffefd5'],
  ['peachpuff', '#ffdab9'],
  ['peru', '#cd853f'],
  ['pink', '#ffc0cb'],
  ['plum', '#dda0dd'],
  ['powderblue', '#b0e0e6'],
  ['purple', '#800080'],
  ['rebeccapurple', '#663399'],
  ['red', '#ff0000'],
  ['rosybrown', '#bc8f8f'],
  ['royalblue', '#4169e1'],
  ['saddlebrown', '#8b4513'],
  ['salmon', '#fa8072'],
  ['sandybrown', '#f4a460'],
  ['seagreen', '#2e8b57'],
  ['seashell', '#fff5ee'],
  ['sienna', '#a0522d'],
  ['silver', '#c0c0c0'],
  ['skyblue', '#87ceeb'],
  ['slateblue', '#6a5acd'],
  ['slategray', '#708090'],
  ['snow', '#fffafa'],
  ['springgreen', '#00ff7f'],
  ['steelblue', '#4682b4'],
  ['tan', '#d2b48c'],
  ['teal', '#008080'],
  ['thistle', '#d8bfd8'],
  ['tomato', '#ff6347'],
  ['turquoise', '#40e0d0'],
  ['violet', '#ee82ee'],
  ['wheat', '#f5deb3'],
  ['white', '#ffffff'],
  ['whitesmoke', '#f5f5f5'],
  ['yellow', '#ffff00'],
  ['yellowgreen', '#9acd32'],
]);

/**
 * Converts a hexadecimal color code to an RGB color array.
 *
 * @param hex - The hexadecimal color code to convert.
 * @returns An array containing the red, green, and blue values of the converted color.
 */
export const hexToRgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5), 16);
  return { color: `rgb(${r}, ${g}, ${b})`, values: [r, g, b] };
};

/**
 * Converts RGB color values to a hexadecimal string representation.
 *
 * @param r - The red color value (0-255).
 * @param g - The green color value (0-255).
 * @param b - The blue color value (0-255).
 * @returns The hexadecimal string representation of the RGB color values.
 */
export const rgbToHex = (rgb: string) => {
  const [R, G, B] = rgb
    .substring(rgb.indexOf('(') + 1, rgb.lastIndexOf(')'))
    .split(/,\s*/)
    .map((c) => parseInt(c, 10).toString(16).padStart(2, '0'));

  return { color: `#${R}${G}${B}`, values: [R, G, B] };
};

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from https://en.wikipedia.org/wiki/HSL_and_HSV#RGB_to_HSL_and_HSV
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns an array of HSL values in the set [0, 360], [0, 100], [0, 100].
 *
 * @param r - The red color value
 * @param g - The green color value
 * @param b - The blue color value
 * @returns An array of HSL values in the set [0, 360], [0, 100], [0, 100]
 */
export const rgbToHsl = (rgb: string) => {
  const [R, G, B] = rgb
    .substring(rgb.indexOf('(') + 1, rgb.lastIndexOf(')'))
    .split(/,\s*/)
    .map((c) => parseInt(c, 10));
  const RED = R / 255;
  const GREEN = G / 255;
  const BLUE = B / 255;

  const MAX = Math.max(RED, GREEN, BLUE);
  const MIN = Math.min(RED, GREEN, BLUE);
  const L = (MAX + MIN) / 2;

  let H = 0;
  let S = 0;

  if (MAX !== MIN) {
    const DIFF = MAX - MIN;
    S = L > 0.5 ? DIFF / (2 - MAX - MIN) : DIFF / (MAX + MIN);

    switch (MAX) {
      case RED:
        H = (GREEN - BLUE) / DIFF + (GREEN < BLUE ? 6 : 0);
        break;
      case GREEN:
        H = (BLUE - RED) / DIFF + 2;
        break;
      case BLUE:
        H = (RED - GREEN) / DIFF + 4;
        break;
      default:
        break;
    }
  }

  return {
    color: `hsl(${Math.round(H * 60)}, ${Math.round(S * 100)}%, ${Math.round(L * 100)}%)`,
    values: [Math.round(H * 60), Math.round(S * 100), Math.round(L * 100)],
  };
};

/**
 * Converts an HSL color value to RGB.
 *
 * @param h - The hue value in degrees (0-360).
 * @param s - The saturation value as a percentage (0-100).
 * @param l - The lightness value as a percentage (0-100).
 * @returns An array of RGB values (0-255) in the format [r, g, b].
 */
export const hslToRgb = (hsl: string) => {
  const hueToRgb = (p: number, q: number, t: number) => {
    let T = t;
    if (T < 0) {
      T += 1;
    }
    if (T > 1) {
      T -= 1;
    }
    if (T < 1 / 6) {
      return p + (q - p) * 6 * T;
    }
    if (T < 1 / 2) {
      return q;
    }
    if (T < 2 / 3) {
      return p + (q - p) * (2 / 3 - T) * 6;
    }
    return p;
  };

  const [h, s, l] = hsl
    .substring(hsl.indexOf('(') + 1, hsl.lastIndexOf(')'))
    .replace(/[deg%]/g, '')
    .split(/,\s*/)
    .map((c) => parseInt(c, 10));

  const H = h / 360;
  const S = s / 100;
  const L = l / 100;

  const q = L < 0.5 ? L * (1 + S) : L + S - L * S;
  const p = 2 * L - q;

  const r = Math.round(hueToRgb(p, q, H + 1 / 3) * 255);
  const g = Math.round(hueToRgb(p, q, H) * 255);
  const b = Math.round(hueToRgb(p, q, H - 1 / 3) * 255);

  return { color: `rgb(${r}, ${g}, ${b})`, values: [r, g, b] };
};

/**
 * Converts a hexadecimal color code to HSL (hue, saturation, lightness) format.
 *
 * @param hex - The hexadecimal color code to convert.
 * @returns The HSL color code as a string.
 */
export const hexToHsl = (hex: string) => {
  const { color: rgb } = hexToRgb(hex);
  return rgbToHsl(rgb);
};

/**
 * Converts an HSL color value to a hexadecimal color string.
 *
 * @param hsl - The HSL color value to convert.
 * @returns The hexadecimal color string.
 */
export const hslToHex = (hsl: string) => {
  const { color: rgb } = hslToRgb(hsl);
  return rgbToHex(rgb);
};

/**
 * Converts a named color to its RGB equivalent.
 *
 * @param color - The name of the color to convert.
 * @returns The RGB equivalent of the named color.
 */
export const namedToRgb = (color: keyof typeof NAMED_COLORS) => {
  return hexToRgb(NAMED_COLORS.get(String(color).toLowerCase()));
};

/**
 * Converts a hex color to a RGB color
 *
 * @param color The color to convert to hex
 * @returns The hex value of the color
 */
export const namedToHex = (color: keyof typeof NAMED_COLORS) => {
  const { color: rgb } = namedToRgb(color);
  return rgbToHex(rgb);
};

/**
 * Converts a named color to its HSL representation.
 *
 * @param color The name of the color to convert.
 * @returns The HSL representation of the named color.
 */
export const namedToHsl = (color: keyof typeof NAMED_COLORS) => {
  const { color: rgb } = namedToRgb(color);
  return rgbToHsl(rgb);
};

/**
 * Determines the type of color format based on the input string.
 *
 * @param color - The color string to check.
 * @returns The type of color format ('hex', 'rgb', 'hsl', or 'unknown').
 */
const colorType = (color: string): 'hex' | 'rgb' | 'hsl' | 'named' | 'unknown' => {
  if (color.startsWith('#')) {
    return 'hex';
  }
  if (color.startsWith('rgb')) {
    return 'rgb';
  }
  if (color.startsWith('hsl')) {
    return 'hsl';
  }
  if (NAMED_COLORS.get(color.toLowerCase())) {
    return 'named';
  }
  return 'unknown';
};

/**
 * Transforms a color from one format to another.
 *
 * @param color The color to transform.
 * @param outputFormat The format to transform the color to.
 * @returns The transformed color.
 */
const colorTransformer = (
  color: string,
  outputFormat: 'hex' | 'rgb' | 'hsl' | 'named',
):
  | {
      color: string;
      values: string[] | number[];
    }
  | undefined => {
  if (colorType(color) === 'hex') {
    return outputFormat === 'hex'
      ? rgbToHex(hexToRgb(color).color)
      : outputFormat === 'rgb'
        ? hexToRgb(color)
        : outputFormat === 'hsl'
          ? hexToHsl(color)
          : undefined;
  }
  if (colorType(color) === 'hsl') {
    return outputFormat === 'hsl'
      ? rgbToHsl(hslToRgb(color).color)
      : outputFormat === 'rgb'
        ? hslToRgb(color)
        : outputFormat === 'hex'
          ? hslToHex(color)
          : undefined;
  }
  if (colorType(color) === 'rgb') {
    return outputFormat === 'rgb'
      ? hexToRgb(rgbToHex(color).color)
      : outputFormat === 'hex'
        ? rgbToHex(color)
        : outputFormat === 'hsl'
          ? rgbToHsl(color)
          : undefined;
  }
  if (colorType(color) === 'named') {
    return outputFormat === 'hex'
      ? namedToHex(color as keyof typeof NAMED_COLORS)
      : outputFormat === 'rgb'
        ? namedToRgb(color as keyof typeof NAMED_COLORS)
        : outputFormat === 'hsl'
          ? namedToHsl(color as keyof typeof NAMED_COLORS)
          : undefined;
  }
  return undefined;
};

/**
 * Darkens the given color by the specified amount.
 *
 * @param color - The color to darken. Can be in hex, rgb or hsl format.
 * @param amount - The amount to darken the color by, specified as a percentage between 0 and 100.
 * @returns The darkened color in hsl format.
 */
export const darken = (color: string, amount: number, outputFormat: 'hex' | 'rgb' | 'hsl' | 'named' = 'hex') => {
  const ctOutput = colorTransformer(color, 'hsl');

  if (!ctOutput || !ctOutput?.color) {
    return undefined;
  }

  const [h, s, l] = ctOutput.color
    .substring(ctOutput.color.indexOf('(') + 1, ctOutput.color.lastIndexOf(')'))
    .replace(/[deg%]/g, '')
    .split(/,\s*/)
    .map((c) => parseInt(c, 10));

  const darkenedHSL = `hsl(${h}, ${s}%, ${Math.max(l - amount, 0)}%)`;

  return colorTransformer(darkenedHSL, outputFormat);
};

/**
 * Lightens the given color by the specified amount.
 *
 * @param color - The color to lighten. Can be in hex, rgb or hsl format.
 * @param amount - The amount to lighten the color by, specified as a percentage between 0 and 100.
 * @returns The lightened color in hsl format.
 */
export const lighten = (color: string, amount: number, outputFormat: 'hex' | 'rgb' | 'hsl' | 'named' = 'hex') => {
  const ctOutput = colorTransformer(color, 'hsl');

  if (!ctOutput || !ctOutput?.color) {
    return undefined;
  }

  const [h, s, l] = ctOutput.color
    .substring(ctOutput.color.indexOf('(') + 1, ctOutput.color.lastIndexOf(')'))
    .replace(/[deg%]/g, '')
    .split(/,\s*/)
    .map((c) => parseInt(c, 10));

  const lightenedHSL = `hsl(${h}, ${s}%, ${l + (100 - l) / (1 + amount / 100)}%)`;

  return colorTransformer(lightenedHSL, outputFormat);
};

/**
 * Returns the text color that should be used for a given background color
 * @param hexColor - the background color
 * @param threshold - the contrast threshold. Defaults to `2.125`, violates the standard of `4.5` but is more aesthetically pleasing
 * @see Reference by WCAG - https://www.w3.org/TR/WCAG20-TECHS/G18.html
 * @returns the text color
 */
export const getTextColorRespectingContrast = (color: string) => {
  const luminanceFactory = (r: number, g: number, b: number) => {
    const RED = 0.2126;
    const GREEN = 0.7152;
    const BLUE = 0.0722;
    const GAMMA = 2.4;

    const [redA, greenA, blueA] = [r, g, b].map((hex) => {
      const fractionalHex = hex / 255;
      return fractionalHex <= 0.03928 ? fractionalHex / 12.92 : ((fractionalHex + 0.055) / 1.055) ** GAMMA;
    });
    return redA * RED + greenA * GREEN + blueA * BLUE;
  };
  const contrastFactory = (lum1: number, lum2: number) => {
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
  };

  const ctOutput = colorTransformer(color, 'hex');
  const [r, g, b] = ctOutput?.color?.match(/\w\w/g)?.map((x: string) => parseInt(x, 16)) ?? [0, 0, 0];
  const luminance = luminanceFactory(r, g, b);
  const whiteContrast = contrastFactory(luminance, 1);

  return whiteContrast > 2.125 ? '#f8f9f9' : '#232629';
};
