import { PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { IColumnDesc } from 'lineupjs';
import {
  BaseVisConfig,
  isScatterConfig,
  isCorrelationConfig,
  isSankeyConfig,
  isHeatmapConfig,
  isBarConfig,
  isHexbinConfig,
  isViolinConfig,
} from 'visyn_core/vis';
import { EWorkbenchView, IOrdinoAppState, IWorkbenchView } from './interfaces';

export function patchVisViewColumnIds(
  columnDesc: (IColumnDesc & {
    [key: string]: any;
  })[],
  view: IWorkbenchView,
): IWorkbenchView {
  if (view.type !== EWorkbenchView.Visualization) {
    return view;
  }
  const newView = cloneDeep(view);
  function updateSelectedColumns(config: BaseVisConfig, columnKey: string) {
    if (!config[columnKey]) {
      return; // no config found for the selected column key -> skip
    }
    const selected: { name: string }[] = Array.isArray(config[columnKey]) ? config[columnKey] : [config[columnKey]];
    const visColumns = selected.map((c) => {
      const col = columnDesc.find((d) => d.column === c?.name);
      if (!col) {
        console.error(`Selected column ${c.name} not found in workbench columns`);
        return null;
      }
      return { id: col.uniqueId, name: col.label || col.column, description: col.description };
    });
    const patchedColumns = Array.isArray(config[columnKey]) ? visColumns.filter((v) => v) : visColumns?.[0];
    newView.parameters.visConfig[columnKey] = patchedColumns;
  }

  const config = view.parameters.visConfig;
  const typeConfigurations = [
    { predicate: isScatterConfig, keys: ['numColumnsSelected', 'facets', 'shape', 'color', 'labelColumns'] },
    { predicate: isCorrelationConfig, keys: ['numColumnsSelected'] },
    { predicate: isSankeyConfig, keys: ['catColumnsSelected'] },
    { predicate: isHeatmapConfig, keys: ['catColumnsSelected', 'color', 'aggregateColumn'] },
    { predicate: isBarConfig, keys: ['catColumnSelected', 'facets', 'group', 'numColumnsSelected', 'aggregateColumn'] },
    { predicate: isHexbinConfig, keys: ['numColumnsSelected', 'color'] },
    { predicate: isViolinConfig, keys: ['numColumnsSelected', 'catColumnSelected', 'subCategorySelected', 'facetBy'] },
  ];
  const typeConfiguration = typeConfigurations.find(({ predicate }) => predicate(config));
  if (typeConfiguration) {
    typeConfiguration.keys.forEach((key) => {
      updateSelectedColumns(config, key);
    });
  }

  return newView;
}

export const viewsReducers = {
  addView(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; view: IWorkbenchView }>) {
    const workbench = state.workbenches[action.payload.workbenchIndex];
    const updatedView = patchVisViewColumnIds(workbench.columnDescs, action.payload.view);
    state.workbenches[action.payload.workbenchIndex]?.views.push(updatedView);
    if (state.focusWorkbenchIndex === action.payload.workbenchIndex) {
      state.midTransition = false;
    }
  },
  setViewParameters(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; viewIndex: number; parameters: any }>) {
    Object.keys(action.payload.parameters).forEach((p) => {
      if (state.workbenches[action.payload.workbenchIndex]) {
        state.workbenches[action.payload.workbenchIndex].views[action.payload.viewIndex].parameters[p] = action.payload.parameters[p];
      }
    });
  },
  removeView(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; viewIndex: number }>) {
    const workbench = state.workbenches[action.payload.workbenchIndex];
    workbench?.views.splice(action.payload.viewIndex, 1);
  },
};
