import update from 'immutability-helper';
import { IOrdinoAppState } from '../interfaces';
import { PersistConfig } from '../persist/types';

export function ordinoStateReconciler(
  inboundState: IOrdinoAppState,
  state: IOrdinoAppState,
  reducedState: IOrdinoAppState,
  config: PersistConfig<IOrdinoAppState>,
) {
  let newState = { ...reducedState };

  // add values to state that are in inboundState but not in state
  Object.entries(inboundState).forEach(([key, inboundValue]) => {
    const originalValue = state[key];
    const reducedValue = reducedState[key];

    if (inboundValue !== undefined && inboundValue !== reducedValue && inboundValue !== originalValue && key !== '_persist') {
      newState = update(newState, { [key]: { $set: inboundValue } });
    }
  });

  // NOTE: Since we do not store the data of the workbench and the data is already in the state do not reload the data,
  // instead just merge the data from the state into the new state and keep the references of the state reference types
  // see https://www.npmjs.com/package/immutability-helper#available-commands
  newState.workbenches.forEach((workbench, index) => {
    const isSameWorkbench = state.workbenches?.[index]?.id === workbench.id;
    // switched to new session, no optimizations recompute everything
    if (!isSameWorkbench) {
      return;
    }

    if (state.workbenches?.[index]?.dataLength) {
      newState = update(newState, { workbenches: { [index]: { dataLength: { $set: state.workbenches[index].dataLength } } } });
      newState = update(newState, { workbenches: { [index]: { data: { $set: state.workbenches[index].data } } } });
      newState = update(newState, { workbenches: { [index]: { dataMap: { $set: state.workbenches[index].dataMap } } } });
    }
  });

  return newState;
}
