import * as React from 'react';
import { Group, Image } from '@mantine/core';
import LogoOrdino from '../assets/logos/Ordino_white_with_colored_dots.svg';
import LogoOrdinoColored from '../assets/logos/Ordino_color.svg';
import LogoOrdinoByDatavisyn from '../assets/logos/Ordino_full_white_with_colored_dots.svg';
import LogoOrdinoByDatavisynColored from '../assets/logos/Ordino_full_color.svg';

export function OrdinoLogo({
  height = 30,
  width = '100%',
  showByDatavisyn = false,
  colored = false,
}: {
  /**
   * Passing just the height or width will preserve the aspect ratio
   */
  width?: number | string;
  height?: number | string;
  showByDatavisyn?: boolean;
  colored?: boolean;
}) {
  return (
    <Group>
      {showByDatavisyn ? (
        colored ? (
          <Image src={LogoOrdinoByDatavisynColored} fit="contain" width={width} alt="Ordino by datavisyn" style={{ height, flex: 'unset' }} />
        ) : (
          <Image src={LogoOrdinoByDatavisyn} fit="contain" width={width} alt="Ordino by datavisyn" style={{ height, flex: 'unset' }} />
        )
      ) : colored ? (
        <Image src={LogoOrdinoColored} fit="contain" width={width} alt="Ordino" style={{ height, flex: 'unset' }} />
      ) : (
        <Image src={LogoOrdino} fit="contain" width={width} alt="Ordino" style={{ height, flex: 'unset' }} />
      )}
    </Group>
  );
}
