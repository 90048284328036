type TransformConfig = {
  /**
   * An array of state paths apply the transform to.
   */
  whitelist?: Array<string>;
  /**
   * An array of state paths to exclude from the transform.
   */
  blacklist?: Array<string>;
};

type TransformFunction<S> = (state: S, key: string, fullState: S) => S;

/**
 * Creates a transform object that can be used with Redux Persist.
 *
 * @template S The type of the state being transformed.
 * @param {TransformFunction<S>} inbound Transform state coming from redux on its way to being serialized and stored.
 * @param {TransformFunction<S>} outbound transform state coming from storage, on its way to be rehydrated into redux
 * @param {TransformConfig} [config={}] The configuration object for the transform.
 * @returns {any} The transform object.
 */
export default function createTransform<S>(inbound: TransformFunction<S>, outbound: TransformFunction<S>, config: TransformConfig = {}): any {
  const whitelist = config.whitelist || null;
  const blacklist = config.blacklist || null;

  function whitelistBlacklistCheck(key: string): boolean {
    if (whitelist && whitelist.indexOf(key) === -1) {
      return true;
    }
    if (blacklist && blacklist.indexOf(key) !== -1) {
      return true;
    }
    return false;
  }

  return {
    in: (state: S, key: string, fullState: S): S => (!whitelistBlacklistCheck(key) && inbound ? inbound(state, key, fullState) : state),
    out: (state: S, key: string, fullState: S): S => (!whitelistBlacklistCheck(key) && outbound ? outbound(state, key, fullState) : state),
  };
}
