import * as React from 'react';
import { scaleLinear } from 'd3-scale';
import { AppShell, Burger, Group, Button, Overlay, Container, Stack, Flex } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { faNewspaper, faBookOpen, faBook, faAt, faFaceSmile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrdinoLogo } from '../components';
import { OrdinoHero } from './components/OrdinoHero';
import { WELCOME_PAGE_BREAKPOINT_SMALL, WELCOME_PAGE_HEADER_HEIGHT } from './constants';
import { DevelopedByAffiliations } from './components/DevelopedByAffiliations';
import { OrdinoBanner } from '../app/Banner';
import { FeaturesCarousel } from './components/FeaturesCarousel';

const navigationItems = [
  {
    title: `What's new?`,
    url: '/pages/news',
    icon: faNewspaper,
  },
  {
    title: 'Documentation',
    url: '/pages/docs',
    icon: faBook,
  },
  {
    title: 'Publications',
    url: '/pages/publications',
    icon: faBookOpen,
  },
];

const footerItems = [
  ...navigationItems,
  {
    title: 'Contact',
    url: '/pages/contact',
    icon: faAt,
  },
  {
    title: 'Terms of use',
    url: '/pages/terms-of-use',
    icon: faFaceSmile,
  },
];

/**
 * Scales for the overlay effect based on the header height and scroll position
 */
const overlay = {
  color: '#000',
  backgroundOpacity: scaleLinear().domain([0, WELCOME_PAGE_HEADER_HEIGHT]).range([0, 0.6]).clamp(true),
  blur: scaleLinear().domain([0, WELCOME_PAGE_HEADER_HEIGHT]).range([0, 20]).clamp(true),
};

export function HomePage() {
  const [opened, { toggle }] = useDisclosure();
  const [isHomePage, setIsHomePage] = React.useState(false);
  const largerThanMediumScreenSize = useMediaQuery(`(min-width: ${WELCOME_PAGE_BREAKPOINT_SMALL})`);

  React.useEffect(() => {
    setIsHomePage(window.location.pathname === '/');
  }, []);

  return (
    <AppShell
      header={{ height: WELCOME_PAGE_HEADER_HEIGHT }}
      aside={{ width: 300, breakpoint: 'md', collapsed: { desktop: true, mobile: !opened } }}
      withBorder={false}
    >
      <AppShell.Header bg="dvGray.9">
        <Group h="100%" px="md">
          <Group justify="space-between" style={{ flex: 1 }}>
            <Group py="sm">{isHomePage ? null : <OrdinoLogo height={30} />}</Group>
            <Group ml="xl" gap="sm" visibleFrom="md">
              {navigationItems.map((item) => (
                <Button
                  key={item.title}
                  renderRoot={(props) => <a href={item.url} {...props} />}
                  variant="subtle"
                  color="white"
                  size="md"
                  fw="normal"
                  leftSection={
                    <Group visibleFrom="lg">
                      <FontAwesomeIcon icon={item.icon} />
                    </Group>
                  }
                >
                  {item.title}
                </Button>
              ))}

              <Button
                renderRoot={(props) => <a href="/app" {...props} />}
                variant="filled"
                size="md"
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  textTransform: 'uppercase',
                }}
              >
                Start analysis
              </Button>
            </Group>
          </Group>
          <Burger opened={opened} onClick={toggle} hiddenFrom="md" size="md" color="white" />
        </Group>
      </AppShell.Header>

      <AppShell.Aside p="md" bg="transparent">
        <Overlay color={overlay.color} backgroundOpacity={overlay.backgroundOpacity.range()[1]} blur={overlay.blur.range()[1]} zIndex={-1} />
        {navigationItems.map((item) => (
          <Button
            key={item.title}
            renderRoot={(props) => <a href={item.url} {...props} />}
            variant="subtle"
            color="white"
            leftSection={<FontAwesomeIcon icon={item.icon} />}
          >
            {item.title}
          </Button>
        ))}

        <Button
          renderRoot={(props) => <a href="/app" {...props} />}
          variant="filled"
          size="md"
          my="md"
          style={{
            backgroundColor: 'white',
            color: 'black',
            textTransform: 'uppercase',
          }}
        >
          START ANALYSIS
        </Button>
      </AppShell.Aside>

      <AppShell.Main>
        <OrdinoBanner zIndex="0" />
        <OrdinoHero />
        <Container size={largerThanMediumScreenSize ? '100%' : 'xl'} my={40}>
          <FeaturesCarousel />
        </Container>
        <Container size="xl" my={80}>
          <DevelopedByAffiliations />
        </Container>
        <Stack justify="center" bg="dvGray.9" mt="xl" py="xl" gap="xl">
          <Flex justify="center" gap="md" wrap="wrap">
            {footerItems.map((item) => (
              <Button
                key={item.title}
                renderRoot={(props) => <a href={item.url} {...props} />}
                variant="subtle"
                color="white"
                size="md"
                fw="normal"
                leftSection={<FontAwesomeIcon icon={item.icon} />}
              >
                {item.title}
              </Button>
            ))}
          </Flex>
          <Group justify="center">
            <OrdinoLogo height={55} showByDatavisyn />
          </Group>
        </Stack>
      </AppShell.Main>
    </AppShell>
  );
}
