import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { IWorkbench } from './interfaces';
import { FilterItem } from './reprovisynApi';
import { store } from './store';

export class RTKError extends Error {
  code: number | string;

  message: string;

  endpointName: string;

  originalArgs: string;

  constructor(code: number | string, message: string, endpointName: string, originalArgs: string) {
    super(message);
    this.code = code;
    this.endpointName = endpointName;
    this.originalArgs = originalArgs;
  }
}

export function getAllFilters(workbench: IWorkbench) {
  return Array.from(new Set(workbench.views.map((v) => v.filters).flat()));
}

export function findViewIndex(uniqueId: string, workbench: IWorkbench): number {
  return workbench.views.findIndex((v) => v.uniqueId === uniqueId);
}

export function isFirstWorkbench(workbench: IWorkbench): boolean {
  return workbench.index === 0;
}

export function isFocusWorkbench(workbench: IWorkbench): boolean {
  const state = store.getState();
  return workbench.index === state.ordino.focusWorkbenchIndex;
}

export function isBeforeContextWorkbench(workbench: IWorkbench): boolean {
  const state = store.getState();
  return workbench.index < state.ordino.focusWorkbenchIndex - 1;
}

export function isContextWorkbench(workbench: IWorkbench): boolean {
  const state = store.getState();
  return workbench.index === state.ordino.focusWorkbenchIndex - 1;
}

export function isNextWorkbench(workbench: IWorkbench): boolean {
  const state = store.getState();
  return workbench.index === state.ordino.focusWorkbenchIndex + 1;
}

export function constructQueryFilter(columnName: FilterItem['col'], operator: FilterItem['op'], searchString: FilterItem['val']): FilterItem {
  return {
    col: columnName,
    op: operator,
    val: searchString,
  };
}

type ReprovisynAction<ResponseType> = ThunkAction<
  QueryActionCreatorResult<
    QueryDefinition<
      Record<string, unknown> | void,
      BaseQueryFn<any, unknown, unknown, Record<string, unknown>, Record<string, unknown>>,
      string,
      ResponseType,
      'api'
    >
  >,
  any,
  any,
  AnyAction
>;

/**
 * Fires an RTK query action and unsubscribes from future updates
 * @example
 *
 *  RTKQuery(postApiV1ReprovisynEntitiesByEntityIdSearch.initiate({ entityId, searchArgs })
 * // => Promise<EntityMeta[]>
 */
export async function RTKQuery<T>(action: ReprovisynAction<T>): Promise<T> {
  const actionPromise = store.dispatch(action);
  const result = await actionPromise;
  actionPromise.unsubscribe();
  if (result.isError) {
    const error = <{ status: string; data: { message: string } }>result.error;
    return Promise.reject(new RTKError(error.status, error.data.message, result.endpointName, JSON.stringify(result.originalArgs)));
  }
  return (result as Extract<typeof result, { data: any }>).data;
}
