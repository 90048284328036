import { reprovisynApi } from './reprovisynApi';
import { UPLOAD_TAG } from './reprovisynBaseApi';

export const enhanceUploadEntityApi: Parameters<typeof reprovisynApi.enhanceEndpoints>[0] = {
  addTagTypes: [UPLOAD_TAG],
  endpoints: {
    getApiV1ReprovisynEntities: {
      providesTags: () => [{ type: UPLOAD_TAG, id: 'List' }],
    },
    getApiV1ReprovisynProxyViews: {
      providesTags: () => [{ type: UPLOAD_TAG, id: 'List' }],
    },
    getApiV1ReprovisynRelations: {
      providesTags: () => [{ type: UPLOAD_TAG, id: 'List' }],
    },
    postApiV1ReprovisynRelations: {
      providesTags: () => [{ type: UPLOAD_TAG, id: 'List' }],
    },
    postApiV1ReprovisynEntitiesCountMulti: {
      providesTags: () => [{ type: UPLOAD_TAG, id: 'List' }],
    },
    postApiV1ReprovisynEntityMappingsMulti: {
      providesTags: () => [{ type: UPLOAD_TAG, id: 'List' }],
    },
  },
};
