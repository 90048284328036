import { v4 as uuidv4 } from 'uuid';
import { EXTENSION_POINT_VISYN_VIEW, pluginRegistry } from 'visyn_core/plugin';
import { EWorkbenchDirection, EWorkbenchView, IWorkbench, IWorkbenchView } from '../store/interfaces';

import {
  EntityMeta,
  FilterItem,
  GlobalQuery,
  NamedIdSet,
  Oncoprint,
  ProxyView,
  RelationDrilldown,
  RelationMn,
  RelationMnSelection,
  RelationOneN,
  RelationOneNSelection,
  RelationOneOne,
  SolventHeatmap,
  ViewChooserConfig,
  Visualization,
  Workbench,
} from '../store/reprovisynApi';
import { EReprovisynRelationType, EWorkbenchViewType } from '../base';
import { dumpWithDefaults } from '../views/internal/ranking/AsyncDataProvider';
import { IViewPluginDesc } from '../tdp_core';

export class WorkbenchUtils {
  /**
   * Generate a plugin id for the ranking view with a given entity id.
   * @param id Unique id describing the ranking view
   * @returns Plugin id
   */
  static getRankingViewId(id: string): string {
    return `reprovisyn_ranking_${id}`;
  }

  static getSolventHeatmapViewId(entityId: string, view: SolventHeatmap): string {
    return `solvent-heatmap_${entityId}_${view.name.replace(/\s/g, '_').toLowerCase()}`;
  }

  static getVisualizationViewId(entityId: string, view: Visualization): string {
    return `visualization_${entityId}_${view.name.replace(/\s/g, '_').toLowerCase()}`;
  }

  static getOncoprintId(relation: RelationOneOne | RelationOneN | RelationMn | RelationDrilldown | RelationOneNSelection | RelationMnSelection): string {
    // only the first view of the Oncoprint workbench configuration can be an oncoprint view, hence we fetch the first view from the list
    const view = relation.workbench.views[0] as Oncoprint;
    return `oncoprint_${relation.source.id}_${view.copynumber ? view.copynumber.entityId : ''}_${view.mutation ? view.mutation.entityId : ''}_${view.fusion ? view.fusion.entityId : ''}_${view.clinicalData ? view.clinicalData.entityId : ''}`;
  }

  static getRelationRankingId(relation: RelationOneOne | RelationOneN | RelationMn | RelationOneNSelection | RelationMnSelection | RelationDrilldown): string {
    let id = `${relation.type}_${relation.source.id}_${relation.target.id}_${relation.target.key}`;

    if (relation.type === EReprovisynRelationType.OrdinoDrilldown || relation.type === EReprovisynRelationType.MToN) {
      id += `_${relation.mapping?.map((m) => m.entity).join('_')}`;
    }
    return WorkbenchUtils.getRankingViewId(id);
  }

  static getProxyViewId(pView: ProxyView): string {
    return `proxy_view_${pView.entityName}_${pView.name.toLowerCase().replace(/[-_ ](.)/g, (_, c) => c.toUpperCase())}`;
  }

  /**
   * Generate a plugin id for the score with a given column id.
   * @param id Unique id describing score
   * @returns Plugin id
   */
  static getScoreId(id: string): string {
    return `reprovisyn_score_${id}`;
  }

  /**
   * Generate a plugin id for the score with a given column id.
   * @param id Unique id describing score
   * @returns Plugin id
   */
  static getAnnotationScoreId(id: string, targetEntityId?: string): string {
    return targetEntityId ? `annotation_score__${id}__${targetEntityId}` : `annotation_score__${id}`;
  }

  /**
   * Generate a plugin id for the score implementation with a given column id.
   * @param id Unique id describing the score implementation
   * @returns Plugin id
   */
  static getScoreImplId(id: string): string {
    return `reprovisyn_score_impl_${id}`;
  }

  /**
   * Generate a plugin id for an aggregated score with a given column id.
   * @param id Unique id describing score
   * @returns Plugin id
   */
  static getAggScoreId(id: string): string {
    return `reprovisyn_agg_score_${id}`;
  }

  static getMultiScatterViewId(relation: RelationOneOne | RelationOneN | RelationMn | RelationOneNSelection | RelationMnSelection | RelationDrilldown): string {
    return `multi-scatter_${relation.source.id}_${relation.workbench.views[0].name}_${relation.target.id}`;
  }

  static configureViews(
    configuredViews: Workbench['views'],
    defaultView: IViewPluginDesc,
    {
      entityId,
      parameters = { previousSelection: [] },
      relation,
    }: {
      entityId: string;
      parameters?: Record<string, any>;
      relation?: RelationOneOne | RelationOneN | RelationMn | RelationOneNSelection | RelationMnSelection | RelationDrilldown;
    },
  ): IWorkbench['views'] {
    const defaultWorkbenchView = {
      name: defaultView?.viewName,
      id: defaultView?.id,
      uniqueId: uuidv4(),
      filters: [],
      type: defaultView?.visynViewType as EWorkbenchView,
      parameters: {
        ...parameters,
      },
    } as IWorkbenchView;

    const visibleViews = configuredViews?.filter((v) => v.open && this.findRegistryViewId(v, { entityId, relation })) ?? [];
    if (!visibleViews?.length) {
      return [defaultWorkbenchView];
    }

    return visibleViews.map((v) => {
      return {
        uniqueId: uuidv4(),
        id: this.findRegistryViewId(v, { entityId, relation }),
        type: v.type,
        filters: [],
        parameters: { ...v, ...parameters },
      };
    }) as IWorkbenchView[];
  }

  static findRegistryViewId(
    view: EntityMeta['workbench']['views'][0],
    {
      entityId,
      relation,
    }: {
      entityId: string;
      relation?: RelationOneOne | RelationOneN | RelationMn | RelationOneNSelection | RelationMnSelection | RelationDrilldown;
    },
  ): string {
    switch (view.type) {
      case EWorkbenchViewType.Ranking:
        if (relation) {
          return WorkbenchUtils.getRelationRankingId(relation);
        }
        return WorkbenchUtils.getRankingViewId(entityId);
      case EWorkbenchViewType.SolventHeatmap:
        return WorkbenchUtils.getSolventHeatmapViewId(entityId, view);
      case EWorkbenchViewType.Visualization:
        return WorkbenchUtils.getVisualizationViewId(entityId, view);
      case EWorkbenchViewType.Oncoprint:
        return WorkbenchUtils.getOncoprintId(relation);
      case EWorkbenchViewType.CoExpression:
      case EWorkbenchViewType.ExpressionVsCopynumber:
        return WorkbenchUtils.getMultiScatterViewId(relation);
      default:
        console.error(`Unknown view type ${view.type}`);
        return '';
    }
  }

  /**
   * Used to configure the first workbench
   * @param id
   * @param queryParams
   * @returns
   */
  static firstWorkbenchConfig({
    entity,
    queryParams,
    isUploaded = false,
    filters = [],
    namedIdSet,
    viewChooser = { sidebarMode: 'expanded' },
  }: {
    entity: EntityMeta;
    queryParams: {
      globalQuery?: GlobalQuery;
    };
    isUploaded?: boolean;
    filters?: FilterItem[];
    namedIdSet?: NamedIdSet;
    viewChooser?: ViewChooserConfig;
  }) {
    const { id: entityId } = entity;
    const view = pluginRegistry.getPlugin(EXTENSION_POINT_VISYN_VIEW, this.getRankingViewId(entityId)); // select entity --> create first workbench with a single ranking view
    if (!view) {
      throw new Error(`No view found in view registry for id "${entityId}"`);
    }
    const workbenchViews = this.configureViews(entity?.workbench?.views, view, { entityId });
    const configuredRanking = view.workbenchConfig?.views?.find((v) => v.type === EWorkbenchView.Ranking);
    const rankingConfig = configuredRanking?.config;
    const dump = rankingConfig?.dataProviderDump;
    const dataProviderDump = dump ? dumpWithDefaults(dump) : null;

    return {
      workbench: {
        id: uuidv4(),
        type: null,
        itemIDType: view?.itemIDType,
        itemName: view.itemName || view.name,
        openSidebar: null,
        defaultViewId: workbenchViews[0].id,
        viewChooser,
        createNextWorkbenchSidebarOpen: false,
        selectedMappings: [],
        index: 0,
        data: [],
        dataMap: {},
        views: workbenchViews,
        selection: [],
        columnDescs: [],
        entityId,
        namedIdSet,
        name: view?.name,
        viewDirection: EWorkbenchDirection.VERTICAL,
        filters,
        isUploaded,
        isLoading: true,
        dataProviderDump,
        rankingConfig,
      } as IWorkbench,
      // always initialize with empty global query
      globalQuery: {
        name: '',
        availableCategories: [],
        defaultCategories: [],
        appliedCategories: [],
      },
      ...queryParams,
    };
  }
}
