/* eslint-disable @typescript-eslint/no-explicit-any */
import { KEY_PREFIX } from '../constants';

import type { Persistoid, PersistConfig } from '../types';
import { KeyAccessState } from '../types';

function defaultSerialize(data: any) {
  return JSON.stringify(data);
}

export function createPersistoid(config: PersistConfig<any>): Persistoid {
  const { blacklist = null, whitelist = null, transforms = [], throttle = 0, storage } = config;
  const storageKey = `${KEY_PREFIX}${config.key}`;

  const serialize = config.serialize === false ? (x: any) => x : typeof config.serialize === 'function' ? config.serialize : defaultSerialize;
  const writeFailHandler = config.writeFailHandler || null;

  // initialize stateful values
  let lastFullState: KeyAccessState = {}; // redux state before applying blacklist, whitelist, and transforms (e.g., to get the `currentSnapshotId` from the Ordino state)
  let lastState: KeyAccessState = {};
  const stagedState: KeyAccessState = {};
  const keysToProcess: string[] = [];
  let timeIterator: any = null;
  let writePromise: Promise<any> | null = null;

  function passWhitelistBlacklist(key: string) {
    if (whitelist && whitelist.indexOf(key) === -1 && key !== '_persist') {
      return false;
    }
    if (blacklist && blacklist.indexOf(key) !== -1) {
      return false;
    }
    return true;
  }

  function onWriteFail(err: any) {
    if (writeFailHandler) {
      writeFailHandler(err);
    }
    if (err && process.env.NODE_ENV !== 'production') {
      console.error('Error storing data', err);
    }
  }

  function writeStagedState() {
    // cleanup any removed keys just before write.
    Object.keys(stagedState).forEach((key) => {
      if (lastState[key] === undefined) {
        delete stagedState[key];
      }
    });

    writePromise = storage.setItem(storageKey, serialize(stagedState), lastFullState).catch(onWriteFail);
  }

  function processNextKey() {
    if (keysToProcess.length === 0) {
      if (timeIterator) {
        clearInterval(timeIterator);
      }
      timeIterator = null;
      return;
    }

    const key: any = keysToProcess.shift();
    if (key === undefined) {
      return;
    }
    const endState = transforms.reduce((subState, transformer) => {
      return transformer.in(subState, key, lastState);
    }, lastState[key]);

    if (endState !== undefined) {
      try {
        stagedState[key] = serialize(endState);
      } catch (err) {
        console.error('redux-persist/createPersistoid: error serializing state', err);
      }
    } else {
      // if the endState is undefined, no need to persist the existing serialized content
      delete stagedState[key];
    }

    if (keysToProcess.length === 0) {
      writeStagedState();
    }
  }

  const update = (state: KeyAccessState) => {
    lastFullState = state;

    // add any changed keys to the queue
    Object.keys(state).forEach((key) => {
      if (!passWhitelistBlacklist(key)) {
        return;
      } // is key space ignored? noop
      if (lastState[key] === state[key]) {
        return;
      } // value unchanged? noop
      if (keysToProcess.indexOf(key) !== -1) {
        return;
      } // is key already queued? noop
      keysToProcess.push(key); // add key to queue
    });

    // if any key is missing in the new state which was present in the lastState,
    // add it for processing too
    Object.keys(lastState).forEach((key) => {
      if (state[key] === undefined && passWhitelistBlacklist(key) && keysToProcess.indexOf(key) === -1 && lastState[key] !== undefined) {
        keysToProcess.push(key);
      }
    });

    // start the time iterator if not running (read: throttle)
    if (timeIterator === null) {
      timeIterator = setInterval(processNextKey, throttle);
    }

    lastState = state;
  };

  const flush = () => {
    while (keysToProcess.length !== 0) {
      processNextKey();
    }
    return writePromise || Promise.resolve();
  };
  return {
    update,
    flush,
  };
}
