import { IColumnDesc, IDataProviderDump } from 'lineupjs';
import { IRow } from 'visyn_core/base';
import { BaseVisConfig } from 'visyn_core/vis';
import { IViewPluginDesc } from '../tdp_core';
import {
  GlobalQuery,
  FilterItem,
  RelationOneN,
  RelationMn,
  RelationDrilldown,
  NamedIdSet,
  NumericalColumn,
  CategoricalColumn,
  BooleanColumn,
  LinkColumn,
  SmilesColumn,
  StringColumn,
  ViewChooserConfig,
  RelationOneNSelection,
  RelationMnSelection,
  RankingConfig,
} from './reprovisynApi';
import type { EWorkbenchUtilsSidebarTab } from '../app/workbench/sidebar/WorkbenchUtilsSidebar';
import { User } from './visynApi';

export type EntityColumnMeta = Partial<BooleanColumn | CategoricalColumn | LinkColumn | NumericalColumn | SmilesColumn | StringColumn>;

/**
 * Check if the given column is a boolean column. Type guard for the EntityColumnMeta type.
 * @param column The column to check
 * @returns True if the column is a boolean column
 */
export function isBooleanColumn(column: EntityColumnMeta): column is BooleanColumn {
  return column.type === 'boolean' || column.type === 'BOOLEAN';
}

/**
 * Check if the given column is a categorical column. Type guard for the EntityColumnMeta type.
 * @param column The column to check
 * @returns True if the column is a categorical column
 */
export function isCategoricalColumn(column: EntityColumnMeta): column is CategoricalColumn {
  return column.type === 'categorical';
}

/**
 * Check if the given column is a link column. Type guard for the EntityColumnMeta type.
 * @param column The column to check
 * @returns True if the column is a link column
 */
export function isLinkColumn(column: EntityColumnMeta): column is LinkColumn {
  return column.type === 'link';
}

/**
 * Check if the given column is a numerical column. Type guard for the EntityColumnMeta type.
 * @param column The column to check
 * @returns True if the column is a numerical column
 */
export function isNumericalColumn(column: EntityColumnMeta): column is NumericalColumn {
  return column.type === 'number';
}

/**
 * Check if the given column is a smiles column. Type guard for the EntityColumnMeta type.
 * @param column The column to check
 * @returns True if the column is a smiles column
 */
export function isSmilesColumn(column: EntityColumnMeta): column is SmilesColumn {
  return column.type === 'smiles';
}

/**
 * Check if the given column is a string column. Type guard for the EntityColumnMeta type.
 * @param column The column to check
 * @returns True if the column is a string column
 */
export function isStringColumn(column: EntityColumnMeta): column is StringColumn {
  return column.type === 'string' || column.type === 'STRING';
}

export const isCombinedColumn = (desc: IColumnDesc) => desc.type === 'stack' || desc.type === 'imposition';

export function isSupportType(column: EntityColumnMeta | { type: string } = { type: '' }): boolean {
  return column.type === 'rank' || column.type === 'selection' || column.type === 'aggregate';
}

export enum EQueryFilterOperator {
  equals = '==',
  notEquals = '!=',
  greaterThan = '>',
  lessThan = '<',
  greaterThanOrEquals = '>=',
  lessThanOrEquals = '<=',
  like = 'LIKE',
  ilike = 'ILIKE',
  isNull = 'IS NULL',
  isNotNull = 'IS NOT NULL',
  in = 'IN',
  notIn = 'NOT IN',
  regex = 'REGEX',
  isTrue = 'IS TRUE',
  isFalse = 'IS FALSE',
}

export enum EWorkbenchView {
  Ranking = 'ranking',
  Visualization = 'visualization',
}

export interface IWorkbenchView {
  /**
   * View id to load the view from the view plugin. it is not unique!
   */
  id: string;
  /**
   * Type of the view.
   */
  type: EWorkbenchView;
  /**
   * Generated id on creation and can be used to differentiate views that may have the same `id`.
   */
  uniqueId: string;
  /**
   * Name of the view, used in the vew header.
   */
  name: string;
  /**
   * Enable if the view is visible when opening the workbench.
   */
  open?: boolean;
  /**
   * Applied filters
   */
  filters: string[];
  /**
   * Applied optional parameters
   */
  parameters?: any;
}

export interface IOrdinoAppState {
  /**
   * List of open views.
   */
  workbenches: IWorkbench[];

  midTransition: boolean;

  /**
   * isAnimating is true when transitioning between workbenches, during the animation.
   */
  isAnimating: boolean;

  /**
   * Id of the current focus view
   */
  focusWorkbenchIndex: number;

  /**
   * Stores the global query name, available, and applied query categories that are used in the current session
   */
  globalQuery?: GlobalQuery;

  /**
   * Id of the current session
   */
  currentSessionId: string;
}

export enum EWorkbenchDirection {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

/**
 * entityId is equivalent to IWorkbench.entityId
 * columnSelection is a mapping subtype, such as "relativecopynumber"
 */
export interface ISelectedMapping {
  entityId: string;
  columnSelection: string;
}

export interface IWorkbench {
  /**
   * Unique id of the workbench. This is used to identify the workbench in the store.
   */
  id: string;
  /**
   * List of open views. The order of the views in this list determines the order they are displayed in the workbench.
   */
  views: IWorkbenchView[];

  /**
   * The id of the default view in the workbench. This is the view that is displayed when the workbench is opened.
   */
  defaultViewId: string;

  dataProviderDump?: IDataProviderDump;

  /**
   * List of selected mappings which are passed to the next workbench when created. Description of ISelectedMapping interface above.
   */
  selectedMappings: ISelectedMapping[];

  viewDirection: EWorkbenchDirection;

  name: string;
  /**
   * itemIDType of the views in a workbench, should match the itemIDType of the default ranking
   */
  itemIDType: string;

  /**
   * The name of the itemIDType, e.g. "gene"
   */
  itemName: string;

  entityId: string;

  index: number;

  /**
   * The list of all rows of the entity.
   */
  data: IRow[];

  /**
   * A map of all rows of the entity where the key is the id of the row. Used to map the selection ids to the name column.
   */
  dataMap: { [key: string]: IRow };

  /**
   * The list of all columns of the entity.
   */
  entityColumns?: (IColumnDesc & { type: EntityColumnMeta['type']; [key: string]: any })[];

  /**
   * The list of all currently visible columns in in the workbench.
   */
  columnDescs: (IColumnDesc & { [key: string]: any })[];
  // TODO: how do we store the lineup-specific column descriptions? give an example?

  /**
   * List selected rows
   */
  selection: IRow['id'][];

  /**
   * Formatting properties of an entity. This includes an id column used for automatically parsing ids in form dialogs
   * (e.g., select3). Also, formatting serves as a central place where the default format of an entity is defined.
   * TODO: will be replaced by a general interface after Ollie's refactoring PR (https://github.com/Caleydo/ordino/pull/368)
   * See field descriptions in https://github.com/datavisyn/reprovisyn/blob/58bc3f2fecf1632571cea7f6606412f7b11b4fd3/src/base/types.ts#L81
   */
  formatting?: {
    titleColumn?: string;
    idColumn: string;
    template?: string;
    /**
     * For select3 forms, this token separator is used for parsing multiple id's pasted into the form dialog
     */
    tokenSeparatorsRegex?: string;
    defaultTokenSeparator?: string;
  };

  openSidebar: EWorkbenchUtilsSidebarTab;

  /**
   * Flag to automatically collapse the view chooser on opening a workbench, e.g., to have maximum space available for the views.
   */
  viewChooser?: ViewChooserConfig;

  /**
   * "createNextWorkbenchSidebar" is the sidebar that appears to the right of a workbench when you want to add a new workbench.
   * It contains options for which mapping types you want in the next workbench.
   * createNextWorkbenchSidebarOpen keeps track of whether or not the details tab is switched open
   */
  createNextWorkbenchSidebarOpen: boolean;

  filters?: FilterItem[];

  /**
   * The id of the named id set of the workbebench.
   */
  namedIdSet?: NamedIdSet;

  type: RelationOneN['type'] | RelationMn['type'] | RelationDrilldown['type'] | RelationOneNSelection['type'] | RelationMnSelection['type'];

  relation?: RelationOneN | RelationMn | RelationDrilldown;

  /**
   * Used to minimize the context view.
   */
  collapsed?: boolean;

  /**
   * Whether the transition mapping can be edited.
   * TODO: generalize in the future to configuring all sidebars of the workbench (and not only the mapping sidebar)
   * @example Opens the workbench sidebar to select attributes that are added as dynamic columns to the ranking view
   * @default true
   */
  editableMapping?: boolean;

  /**
   * Whether the workbench entity is uploaded or not.
   */
  isUploaded?: boolean;

  /**
   * Minimum required input selection to show the workbench
   * @default 1
   */
  minSelection?: number;

  /**
   * Maximum supported input selection to show the workbench
   * @default -1 (no maximum)
   */
  maxSelection?: number;

  /**
   * Initial vis config of the workbench
   */
  initialVisConfig?: BaseVisConfig & { [key: string]: any };

  /**
   * Row count of current workbench
   */
  dataLength?: number;

  /**
   * Which toolbars are hidden in the current workbench. Should be configured in the config file in the future.
   */
  hiddenToolbarActions?: EWorkbenchUtilsSidebarTab[];

  /**
   * isAnimating is true when the workbench is still loading data.
   */
  isLoading: boolean;
  /**
   * Initial config for the ranking view
   */
  rankingConfig?: RankingConfig;
}

interface IBaseState {
  selection: string[];
}

export interface IOrdinoViewPlugin<S extends IBaseState> extends IViewPluginDesc {
  state: S;
}

export enum EVisibility {
  Private = 'private',
  Public = 'public',
}

export interface INamedIdSetFormValues {
  name: string;
  description?: string;
  visibility?: EVisibility;
  public: boolean;
  collabs?: number[];
}

export interface IUploadFormValues {
  name: string;
  description: string;
  color: string;
  itemName?: string;
  columns: { id: string; name: string; type: EntityColumnMeta['type']; idtype?: string }[];
  visibility?: EVisibility;
  public: boolean;
  collabs?: User[];
}
