import {
  IColumnConstructor,
  IColumnDesc,
  IDataProviderOptions,
  ILocalDataProviderOptions,
  isSupportType,
  ITypeFactory,
  LocalDataProvider,
  StringColumn,
  Column,
  IDataProviderDump,
  IColumnDump,
  IRankingDump,
  Ranking,
  ValueColumn,
  EAggregationState,
  IGroup,
} from 'lineupjs';
import merge from 'lodash/merge';
import { v4 as uuidv4 } from 'uuid';
import { pluginRegistry } from 'visyn_core/plugin';
import { EXTENSION_POINT_TDP_SCORE_IMPL, LineupUtils } from '../../../tdp_core';
import { StructureImageColumn } from '../../../tdp_core/lineup/structureImage';

export const dumpWithDefaults = (dump: Partial<IDataProviderDump>): IDataProviderDump => {
  return merge(
    {
      $schema: 'https://lineup.js.org/develop/schema.4.0.0.json',
      aggregations: {},
      rankings: [
        {
          columns: [],
          groupColumns: [],
          groupSortCriteria: [],
          sortCriteria: [],
        },
      ],
      selection: [],
      showTopN: 10,
    },
    dump,
  );
};

interface IAsyncTypeFactory {
  (d: IColumnDump): Promise<Column>;
  categoricalColorMappingFunction: ITypeFactory['categoricalColorMappingFunction'];
  mappingFunction: ITypeFactory['mappingFunction'];
  colorMappingFunction: ITypeFactory['colorMappingFunction'];
}

export class AsyncDataProvider extends LocalDataProvider {
  asyncTypeFactory: IAsyncTypeFactory;

  private _isLoadingDump = false;

  constructor(_data: any[], columns: IColumnDesc[] = [], options: Partial<ILocalDataProviderOptions & IDataProviderOptions> = {}) {
    super(_data, columns, options);
  }

  /**
   * Injects an async accessor into the column dump, if the column is lazy, the accessor is loaded and the column is marked as loaded
   * Does this recursively for composite columns
   */
  async injectAsyncAccessor(d: IColumnDump): Promise<IColumnDump> {
    const desc = this.fromDescRef(d.desc);
    if (desc?.isLazy) {
      const accessor = LineupUtils.createAccessor(desc);
      // eslint-disable-next-line new-cap
      const params = (<any>desc).scoreParams;
      const pluginDesc = pluginRegistry.getPlugin(EXTENSION_POINT_TDP_SCORE_IMPL, (<any>desc).scoreParams.pluginId);

      const loadData = async () => {
        const plugin = await pluginDesc.load();
        const score = plugin.factory(params, pluginDesc);
        const singleScore = Array.isArray(score) ? score[0] : score; // only ever single scores are added
        return singleScore.compute();
      };

      accessor.setRows(await loadData());
      (<any>desc).reload = async () => {
        const column = <ValueColumn<any>>this.getLastRanking().children.find((c) => c.desc === desc);
        if (!column) {
          return;
        }
        column.setLoaded(false);
        accessor.clear();
        accessor.setRows(await loadData());
        column.setLoaded(true);
      };

      return { ...d, desc, loaded: true }; // from this point on the column is loaded
    }

    // composite column
    if (d.children) {
      const injectAccessorIntoChild = (child: IColumnDump) => this.injectAsyncAccessor(child);
      const children = await Promise.all(d.children.map(injectAccessorIntoChild));
      return { ...d, children };
    }
    // not composite or lazy column
    return Promise.resolve(d);
  }

  toDescRef(desc: any) {
    const descRef = desc.uniqueId ? `${desc.type}@${desc.uniqueId}` : this.cleanDesc({ ...desc }); // support columns
    return descRef;
  }

  fromDescRef(descRef: any) {
    if (typeof descRef === 'string') {
      const desc = this.getColumns().find((d: any) => `${d.type}@${d.uniqueId}` === descRef || d.type === descRef);
      return desc;
    }
    // actual desc object
    return descRef;
  }

  async restoreRankingAsync(ranking: Ranking, dump: IRankingDump) {
    ranking.clear();

    const columns = await Promise.all(
      (dump.columns || []).map(async (child: any) => {
        const loadedChild = await this.injectAsyncAccessor(child); // inject the async accessor, this is the only difference to the original method
        const factory = this.getTypeFactory();
        return factory({ ...loadedChild });
      }),
    );
    columns.forEach((c) => ranking.push(c));
    // compatibility case
    if (dump.sortColumn && dump.sortColumn.sortBy) {
      const help = ranking.children.find((d) => d.id === dump.sortColumn!.sortBy);
      if (help) {
        ranking.sortBy(help, dump.sortColumn.asc);
      }
    }

    if (dump.groupColumns) {
      const groupColumns = dump.groupColumns.map((id: string) => ranking.flatColumns.find((d) => d.id === id)).filter((d) => d != null) as Column[];
      ranking.setGroupCriteria(groupColumns);
    }

    const restoreSortCriteria = (dumped: any) => {
      return dumped
        .map((s: { asc: boolean; sortBy: string }) => {
          return {
            asc: s.asc,
            col: ranking.flatColumns.find((d) => d.id === s.sortBy) || null,
          };
        })
        .filter((s: any) => s.col);
    };

    if (dump.sortCriteria) {
      ranking.setSortCriteria(restoreSortCriteria(dump.sortCriteria));
    }

    if (dump.groupSortCriteria) {
      ranking.setGroupSortCriteria(restoreSortCriteria(dump.groupSortCriteria));
    }
  }

  isLoadingDump() {
    return this._isLoadingDump;
  }

  async restoreAsync(dump: IDataProviderDump): Promise<void> {
    this._isLoadingDump = true;
    this.clearRankings();
    const rankingDump = dump.rankings[0];
    const ranking = this.cloneRanking(); // this increments the ranking id
    ranking.assignNewId(() => `rank${0}`); // we only ever store one ranking so always use the same id

    if (rankingDump) {
      await this.restoreRankingAsync(ranking, rankingDump);
      this.insertRanking(ranking);
      ranking.children.forEach((c) => c.assignNewId(uuidv4));
    }
    if (dump.showTopN) {
      this.setShowTopN(dump.showTopN);
    }
    const EXPANDED_GROUP = -1;
    if (dump.aggregations) {
      // function to convert a number to an aggregation state
      const toAggregationsState = (v: number) => {
        switch (v) {
          case 0:
            return EAggregationState.COLLAPSE;
          case EXPANDED_GROUP:
            return EAggregationState.EXPAND;
          default:
            return EAggregationState.EXPAND_TOP_N;
        }
      };

      // same as original method but adapted to use the public methods as we cannot access the aggregation state directly
      const groupId = (g: IGroup) => `${ranking.id}@${g.name}`;
      await new Promise((resolve) => {
        ranking.on(`${Ranking.EVENT_GROUPS_CHANGED}.async`, () => {
          ranking.on(`${Ranking.EVENT_GROUPS_CHANGED}.async`, null);
          const groups = ranking.getFlatGroups();
          const { aggregations } = dump;
          if (Array.isArray(aggregations)) {
            const input = groups.filter((d) => aggregations.includes(d.name));
            this.setAggregationState(ranking, input, EAggregationState.COLLAPSE);
          } else {
            const aggregationsMap = new Map();
            for (const group of groups) {
              const value = aggregations[groupId(group)] ?? EXPANDED_GROUP; // dump only contains collapsed groups so default to -1 expanded
              if (!aggregationsMap.has(value)) {
                aggregationsMap.set(value, []);
              }
              aggregationsMap.get(value).push(group);
            }
            for (const [v, g] of aggregationsMap) {
              this.setAggregationState(ranking, g, toAggregationsState(v));
            }
          }
          resolve(null);
        });
      });
    }

    this._isLoadingDump = false;
    return Promise.resolve();
  }

  dump(): IDataProviderDump {
    // @NOTE: do not store uid in the dump as it changes on every restore
    const dump = super.dump();
    const { uid, ...rest } = dump;
    rest.rankings = [rest.rankings[0]]; // only store the first ranking
    return rest;
  }

  /**
   * Code taken from TDPLocalDataProvider
   * We no longer extend TDPLocalDataProvider
   */
  protected instantiateColumn(type: IColumnConstructor, id: string, desc: IColumnDesc, typeFactory: ITypeFactory) {
    // cache the column width because initializing the `type` class mutates the desc object
    const columnWidth = desc.width;

    // create a column instance needed for the `isSupportType(col)`
    // eslint-disable-next-line new-cap
    const col = new type(id, desc, typeFactory);

    // do nothing if column width is already defined, there is a default width set by the column instance, or it is a support type column (e.g., rank, aggregation, selection)
    if (columnWidth >= 0 || (!columnWidth && col.getWidth() >= 0) || isSupportType(col)) {
      return col;
    }

    if (type === StringColumn) {
      col.setWidthImpl(120); // use `setWidthImpl` instead of `setWidth` to avoid triggering an event
    } else if (type === StructureImageColumn) {
      col.setWidthImpl(70);
    } else {
      col.setWidthImpl(102);
    }

    return col;
  }
}
