import type { KeyAccessState, PersistConfig } from './types';
import { KEY_PREFIX } from './constants';

function defaultDeserialize(serial: string) {
  return JSON.parse(serial);
}

export function getStoredState(config: PersistConfig<any>, lastFullState: KeyAccessState): Promise<{ [key: string]: any } | void> {
  const storageKey = `${KEY_PREFIX}${config.key}`;
  const { storage, debug, transforms = [] } = config;
  const deserialize = config.deserialize === false ? (x: any) => x : typeof config.deserialize === 'function' ? config.deserialize : defaultDeserialize;

  return storage.getItem(storageKey, lastFullState).then((serialized: any) => {
    if (!serialized) {
      return undefined;
    }

    try {
      const state: KeyAccessState = {};
      const rawState = deserialize(serialized);

      Object.keys(rawState).forEach((key) => {
        state[key] = transforms.reduceRight((subState, transformer) => {
          return transformer.out(subState, key, rawState);
        }, deserialize(rawState[key]));
      });

      return state;
    } catch (err) {
      if (process.env.NODE_ENV !== 'production' && debug) {
        console.log(`redux-persist/getStoredState: Error restoring data ${serialized}`, err);
      }
      throw err;
    }
  });
}
