import { cx, css } from '@emotion/css';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Text, Group } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import React, { useEffect } from 'react';
import { MarkdownText } from '../components/MarkdownText';
import { Banner, useGetApiV1ReprovisynAppConfigBannerQuery } from '../store';
import { getTextColorRespectingContrast } from '../views/internal/ColorUtils';
import { LS_BANNER_INFO_KEY, LS_SHOW_BANNER_KEY } from './constants';

/**
 * The banner that is shown at the top of the page and can be used to display important information to the user.
 * - The banner is shown if the message provided by the server is not empty.
 * - The banner is hidden if the user closes it or it is automatically hidden if the (updated) message is empty.
 * - The banner is shown again if the message changes.
 *
 * The `showBanner` and `bannerInfo` are stored in the local storage to persist the state of the banner.
 * The `showBanner` is used in the `Ordino` component to add a class to the main container to adjust the layout when the banner is shown.
 *
 * @returns OrdinoBanner
 */
export function OrdinoBanner({ zIndex }: { zIndex?: string | number }) {
  // set `getInitialValueInEffect: false` because don't need the initial value and wait for the server response and then set the value
  const [showBanner, setShowBanner] = useLocalStorage<boolean>({ key: LS_SHOW_BANNER_KEY, defaultValue: false, getInitialValueInEffect: false });
  const [bannerLocalStorage, setBannerInfo] = useLocalStorage<Banner>({ key: LS_BANNER_INFO_KEY, defaultValue: null, getInitialValueInEffect: false });
  const { data: bannerServer, isSuccess: isBannerRequestSuccessful } = useGetApiV1ReprovisynAppConfigBannerQuery();

  useEffect(() => {
    if (isBannerRequestSuccessful === false) {
      return;
    }
    setBannerInfo(bannerServer);
    setShowBanner(bannerServer?.message?.length > 0); // Hide the banner if the message is empty
  }, [bannerServer?.message, bannerServer, isBannerRequestSuccessful, setBannerInfo, setShowBanner]);

  if (isBannerRequestSuccessful && showBanner && bannerLocalStorage?.message?.length > 0) {
    return (
      <Group
        justify="space-between"
        bg={bannerLocalStorage.backgroundColor}
        p="xs"
        wrap="nowrap"
        style={{ position: 'relative', zIndex: zIndex || 100 }}
        data-testid="ordino-banner"
      >
        <Text
          c={getTextColorRespectingContrast(bannerLocalStorage.backgroundColor)}
          id="markdown-holder"
          data-testid="ordino-banner-message"
          className={cx(css`
            & p {
              margin: 0;
            }
            & a {
              color: ${getTextColorRespectingContrast(bannerLocalStorage.backgroundColor)};
            }
          `)}
          component="div"
        >
          <MarkdownText>{bannerLocalStorage.message}</MarkdownText>
        </Text>
        <ActionIcon variant="transparent" c={getTextColorRespectingContrast(bannerLocalStorage.backgroundColor)} data-testid="ordino-banner-close">
          <FontAwesomeIcon icon={faTimes} onClick={() => setShowBanner(false)} />
        </ActionIcon>
      </Group>
    );
  }

  return null;
}
