import {
  Category,
  FilterItem,
  GlobalQuery,
  RelationDrilldown,
  RelationMn,
  RelationMnSelection,
  RelationOneN,
  RelationOneNSelection,
} from '../store/reprovisynApi';

export const VISYN_ID = 'id';

export enum EColumnType {
  categorical = 'categorical',
  number = 'number',
  string = 'string',
  boolean = 'boolean',
  link = 'link',
  smiles = 'smiles',
}

/**
 * reprovisyn relation type
 */
export enum EReprovisynRelationType {
  OneToN = '1-n',
  MToN = 'm-n',
  OneToNSelection = '1-n-selection',
  MToNSelection = 'm-n-selection',
  OrdinoDrilldown = 'ordino-drilldown',
}

export enum EReprovisynNamedIdSetSource {
  config = 'config',
  user = 'user',
  data_table = 'data_table',
}

export type GlobalQueryCategories = Category[];

export type QueryFilterProps = {
  globalQuery?: GlobalQuery;
  namedIdSetId?: number;
  filter?: FilterItem[];
};

export function isRelationDrilldown(
  relation: RelationOneN | RelationDrilldown | RelationMn | RelationOneNSelection | RelationMnSelection,
): relation is RelationDrilldown {
  return relation.type === EReprovisynRelationType.OrdinoDrilldown;
}

/**
 * Supported workbench view types that can be defined in the data landscape
 * Currently the ranking cannot be configured in the data landscape, it is added by default
 */
export enum EWorkbenchViewType {
  Ranking = 'ranking',
  Visualization = 'visualization',
  CoExpression = 'co-expression',
  ExpressionVsCopynumber = 'expression-vs-copynumber',
  Oncoprint = 'oncoprint',
  SolventHeatmap = 'solvent-heatmap',
}
