import { i18n } from 'visyn_core/i18n';
import { namedIdSetsApiEnhancements } from './enhanceNamedIdSetsApi';
import { enhanceUploadEntityApi } from './enhanceUploadEntityApi';
import { EntityMeta, reprovisynApi } from './reprovisynApi';
import { ENTITY_RELATION_TAG } from './reprovisynBaseApi';

const apiEnhancements: {
  addTagTypes?: readonly string[];
  endpoints?: object;
} = {
  addTagTypes: [],
  endpoints: {
    getApiV1ReprovisynEntities: {
      providesTags: () => [{ type: ENTITY_RELATION_TAG, id: 'List' }],
    },
    getApiV1ReprovisynRelations: {
      providesTags: () => [{ type: ENTITY_RELATION_TAG, id: 'List' }],
    },
    postApiV1ReprovisynEntitiesByEntityIdMetadata: {
      transformResponse: (response: EntityMeta) => {
        if (response?.formatting) {
          i18n.addResource('en', 'reprovisyn', `entity.${response.id}`, response.formatting.template);
        }
        return response;
      },
    },
  },
};

export function addApiEnhancements() {
  [apiEnhancements, namedIdSetsApiEnhancements, enhanceUploadEntityApi].forEach((e) => reprovisynApi.enhanceEndpoints(e));
}
