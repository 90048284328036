import { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import { IRow, IServerColumn } from 'visyn_core/base';
import { IColumnDump } from 'lineupjs';
import {
  EAggregateTypes,
  ESupportedPlotlyVis,
  BaseVisConfig,
  isBarConfig,
  isCorrelationConfig,
  isHexbinConfig,
  isScatterConfig,
  isViolinConfig,
} from 'visyn_core/vis';
import type { EWorkbenchUtilsSidebarTab } from '../app/workbench/sidebar/WorkbenchUtilsSidebar';
import { EWorkbenchDirection, IOrdinoAppState, ISelectedMapping, IWorkbench, isSupportType } from './interfaces';
import { FilterItem, GlobalQuery, NamedIdSet } from './reprovisynApi';
import { patchVisViewColumnIds } from './viewsReducer';

export const workbenchReducers = {
  addFirstWorkbench(
    state: IOrdinoAppState,
    action: PayloadAction<{
      workbench: IWorkbench;
      globalQuery?: GlobalQuery;
    }>,
  ) {
    state.focusWorkbenchIndex = 0;
    state.workbenches = [action.payload.workbench];
    state.globalQuery = action.payload.globalQuery;
    state.midTransition = false;
  },
  addWorkbench(state: IOrdinoAppState, action: PayloadAction<IWorkbench>) {
    state.midTransition = true;
    if (state.workbenches.length > action.payload.index) {
      state.workbenches.splice(action.payload.index);
    }
    state.workbenches.push(action.payload);
  },
  changeSelectedMappings(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; newMapping: ISelectedMapping }>) {
    const currentWorkbench = state.workbenches[action.payload.workbenchIndex];

    const { newMapping } = action.payload;
    if (
      !currentWorkbench.selectedMappings.find((m) => {
        const { entityId, columnSelection } = newMapping;
        return m.entityId === entityId && m.columnSelection === columnSelection;
      })
    ) {
      currentWorkbench.selectedMappings.push(newMapping);
    } else {
      currentWorkbench.selectedMappings = currentWorkbench.selectedMappings.filter((m) => {
        const { entityId, columnSelection } = newMapping;
        return !(m.entityId === entityId && m.columnSelection === columnSelection);
      });
    }
  },
  setDataProviderDump(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; dataProviderDump }>) {
    state.workbenches[action.payload.workbenchIndex].dataProviderDump = action.payload.dataProviderDump;
  },

  setCollapsed(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; collapsed: boolean }>) {
    state.workbenches[action.payload.workbenchIndex].collapsed = action.payload.collapsed;
  },
  setMidTransition(state: IOrdinoAppState) {
    state.midTransition = true;
    state.focusWorkbenchIndex -= 1;
  },
  setOpenSidebar(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; sidebar: EWorkbenchUtilsSidebarTab }>) {
    if (state.workbenches[action.payload.workbenchIndex]) {
      state.workbenches[action.payload.workbenchIndex].openSidebar = action.payload.sidebar;
    }
  },
  setCreateNextWorkbenchSidebarOpen(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; open: boolean }>) {
    state.workbenches[action.payload.workbenchIndex].createNextWorkbenchSidebarOpen = action.payload.open;
  },
  createColumnDescs(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; desc: any }>) {
    const { workbenchIndex, desc } = action.payload;
    const columnDescsWithUniqueId = desc.map((d) => {
      const uniqueId = uuidv4();
      return { ...d, uniqueId };
    }); // uniqueId is use by views to identify columns, id is use by LineUp to dump/restore columns

    const categoricalColumn = columnDescsWithUniqueId.find((c) => c.type === 'categorical' && c.initialRanking);
    const defaultVisConfig = {
      type: ESupportedPlotlyVis.BAR,
      catColumnSelected: categoricalColumn ? { id: categoricalColumn.uniqueId, name: categoricalColumn.label, description: null } : null,
      aggregateType: EAggregateTypes.COUNT,
    };
    // patch dataProviderDump with the uniqueIds
    const { dataProviderDump } = state.workbenches[workbenchIndex];
    if (dataProviderDump) {
      const uniqueIdMap = new Map<string, any>(columnDescsWithUniqueId.map((c) => [c.column, c]));
      const toColumnDump = (colDump: IColumnDump) => {
        // support type columns have to be fully configured
        if (isSupportType(colDump?.desc)) {
          return colDump;
        }
        // combined columns
        if (colDump?.children?.length > 0) {
          const uniqueId = uuidv4();
          return { ...colDump, width: 200, id: uniqueId, desc: { ...colDump.desc, id: uniqueId }, children: colDump.children.map(toColumnDump) };
        }

        // simple column
        const colDesc = uniqueIdMap.get(colDump.column);
        const uniqueId = colDesc?.uniqueId;
        if (!uniqueId) {
          return null;
        }
        const descRef = `${colDesc.type}@${uniqueId}`;
        delete colDump.column; // allow overriding the initial column desc in the data provider dump from the landscape (e.g., to define the `groupCriteria` of a column)
        const fullColumnDump = { id: uniqueId, uniqueId, desc: descRef, ...colDump };
        return fullColumnDump;
      };
      const columnDump = dataProviderDump.rankings[0].columns as IColumnDump[];
      const columnDumpFinal = columnDump.map(toColumnDump).filter((d) => d) as IColumnDump[];
      dataProviderDump.rankings[0].columns = columnDumpFinal;
      if (dataProviderDump.rankings[0].groupColumns?.length) {
        const { groupColumns } = dataProviderDump.rankings[0];
        dataProviderDump.rankings[0].groupColumns = groupColumns.map((col) => uniqueIdMap.get(col)?.uniqueId);
      }
      if (dataProviderDump.rankings[0].sortCriteria?.length) {
        const { sortCriteria } = dataProviderDump.rankings[0];
        dataProviderDump.rankings[0].sortCriteria = sortCriteria.map((col) => ({ ...col, sortBy: uniqueIdMap.get(col.sortBy)?.uniqueId }));
      }
      if (dataProviderDump.rankings[0].groupSortCriteria?.length) {
        const { groupSortCriteria } = dataProviderDump.rankings[0];
        dataProviderDump.rankings[0].groupSortCriteria = groupSortCriteria.map((col) => ({ ...col, sortBy: uniqueIdMap.get(col.sortBy)?.uniqueId }));
      }
    }
    state.workbenches[workbenchIndex].views.forEach((view, i) => {
      const updatedView = patchVisViewColumnIds(columnDescsWithUniqueId, view);
      if (updatedView) {
        state.workbenches[workbenchIndex].views[i] = updatedView;
      }
    });
    state.workbenches[workbenchIndex].initialVisConfig = defaultVisConfig;
    state.workbenches[workbenchIndex].entityColumns = columnDescsWithUniqueId;
    state.workbenches[workbenchIndex].columnDescs = columnDescsWithUniqueId.filter((d) => d.initialRanking);
  },
  setTransitionColumns(
    state: IOrdinoAppState,
    action: PayloadAction<{
      workbenchIndex: number;
      add: IServerColumn[];
      remove: IServerColumn[];
    }>,
  ) {
    const { workbenchIndex, add, remove } = action.payload;
    // Ensure the workbench exists
    if (workbenchIndex >= 0 && workbenchIndex < state.workbenches.length) {
      // Remove the specified columns
      if (remove && remove.length > 0) {
        remove.forEach((item) => {
          const index = findIndex(state.workbenches[workbenchIndex].columnDescs, (c) => c.uniqueId === (<any>item).uniqueId);
          if (index !== -1) {
            state.workbenches[workbenchIndex].columnDescs.splice(index, 1);
          }
        });
      }

      // Add the new columns
      if (add && add.length > 0) {
        const cloned = cloneDeep(add);
        // NOTE: In some cases the order property is added to the columnDescs, which causes the comparison to fail.
        // This is just a quick fix but we should investigate why this is happening.
        cloned.forEach((item) => {
          delete (item as any).mappingRelationArgs.source.order;
          delete (item as any).mappingRelationArgs.target.order;
        });
        state.workbenches[workbenchIndex].columnDescs.push(...cloned);
      }
    }
  },

  addColumnDesc(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; desc: any }>) {
    const { workbenchIndex, desc } = action.payload;
    // don't add column descs if workbench doesn't exist
    if (!state.workbenches[workbenchIndex]) {
      return;
    }

    // NOTE: When restoring a transition a workbench the SelectionAdapter columns will be recomputed again because of the mappings
    if (state.workbenches[workbenchIndex].columnDescs.find((c) => c.uniqueId === desc.uniqueId)) {
      return;
    }

    // add uniqueId to each columnDesc to support duplicate columns
    const columnDescWithUniqueId = { ...desc, uniqueId: desc.uniqueId || uuidv4() };
    state.workbenches[workbenchIndex].columnDescs.push(columnDescWithUniqueId);
  },
  removeColumnDesc(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; uniqueId: string }>) {
    const { workbenchIndex, uniqueId } = action.payload;
    const columnIndex = state.workbenches[workbenchIndex].columnDescs.findIndex((c) => c.uniqueId === uniqueId);
    const { views } = state.workbenches[workbenchIndex];

    // clear column selection in vis configs if column is removed and is not a duplicate
    views.forEach((view, viewIndex) => {
      const visConfig = view?.parameters?.visConfig;
      if (visConfig) {
        if (
          (isScatterConfig(visConfig) || isCorrelationConfig(visConfig) || isViolinConfig(visConfig) || isBarConfig(visConfig) || isHexbinConfig(visConfig)) &&
          visConfig.numColumnsSelected?.some((c) => c.id === uniqueId)
        ) {
          state.workbenches[workbenchIndex].views[viewIndex].parameters.visConfig.numColumnsSelected = [];
        }

        if (isBarConfig(visConfig) && visConfig.catColumnSelected?.id === uniqueId) {
          state.workbenches[workbenchIndex].views[viewIndex].parameters.visConfig.catColumnSelected = null;
        }

        if (isViolinConfig(visConfig) && visConfig.catColumnSelected?.id === uniqueId) {
          state.workbenches[workbenchIndex].views[viewIndex].parameters.visConfig.catColumnSelected = null;
        }
      }
    });

    if (columnIndex !== -1) {
      state.workbenches[workbenchIndex].columnDescs.splice(columnIndex, 1);
    }
  },
  setInitialVisConfig(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; visConfig: BaseVisConfig }>) {
    state.workbenches[action.payload.workbenchIndex].initialVisConfig = action.payload.visConfig;
  },
  setWorkbenchDirection(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; direction: EWorkbenchDirection }>) {
    state.workbenches[action.payload.workbenchIndex].viewDirection = action.payload.direction;
  },
  removeWorkbench(state: IOrdinoAppState, action: PayloadAction<{ index: number }>) {
    state.workbenches.splice(action.payload.index, state.workbenches.length);
  },
  removeWorkbenchOnEntityDelete(state: IOrdinoAppState, action: PayloadAction<{ entityId: string }>) {
    // on delete, remove any workbench of this entity, also remove any workbench following it
    const deletedEntityIdx = state.workbenches.findIndex((workbench) => workbench.entityId === action.payload.entityId);
    if (deletedEntityIdx !== -1) {
      // we want to go one index beyond the deleted entity, because also the previous workbench's view chooser needs to be wiped
      // (because we currently don't support reloading the view chooser, we delete the workbench instead)
      for (let inverseIndex = state.workbenches.length - 1; inverseIndex >= deletedEntityIdx - 1; inverseIndex--) {
        state.workbenches.splice(inverseIndex, 1);
      }
    }
  },

  replaceWorkbench(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; newWorkbench: IWorkbench }>) {
    state.workbenches.splice(action.payload.workbenchIndex);
    state.workbenches.push(action.payload.newWorkbench);
  },
  addSelection(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; newSelection: string[] }>) {
    const { workbenchIndex, newSelection } = action.payload;
    state.workbenches[workbenchIndex].selection = newSelection;
  },
  addRankingFilter(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; viewId: string; filter: string[] }>) {
    state.workbenches[action.payload.workbenchIndex].views.find((v) => v.uniqueId === action.payload.viewId).filters = action.payload.filter;
  },
  setWorkbenchFilter(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; filter: FilterItem[] }>) {
    const { workbenchIndex, filter } = action.payload;
    state.workbenches[workbenchIndex].filters = filter;
  },
  setWorkbenchNamedIdSet(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; namedIdSet: NamedIdSet }>) {
    const { workbenchIndex, namedIdSet } = action.payload;
    state.workbenches[workbenchIndex].namedIdSet = namedIdSet;
  },
  setWorkbenchDataLoading(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; isLoading: boolean }>) {
    const { workbenchIndex, isLoading } = action.payload;
    state.workbenches[workbenchIndex].isLoading = isLoading;
  },
  setWorkbenchData(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; data: IRow[] }>) {
    const { workbenchIndex, data } = action.payload;

    if (!data?.length) {
      state.workbenches[workbenchIndex].openSidebar = null;
      state.workbenches[workbenchIndex].dataLength = 0; // data length is 0: show no data message, dataLength is null: show loading message
      return;
    }

    state.workbenches[workbenchIndex].data = [];
    state.workbenches[workbenchIndex].dataMap = {};

    data.forEach((row) => {
      // remove duplicate rows by keeping the first row with a given id and remove all following rows with the same id
      if (!state.workbenches[workbenchIndex].dataMap[row.id]) {
        state.workbenches[workbenchIndex].dataMap[row.id] = row;
        state.workbenches[workbenchIndex].data.push(row);
      }
    });

    // update data length after data is set and duplicate values are removed
    state.workbenches[workbenchIndex].dataLength = state.workbenches[workbenchIndex].data.length;
    if (state.workbenches[workbenchIndex].dataLength > 0) {
      state.workbenches[workbenchIndex].isLoading = false;
    }
  },
};
