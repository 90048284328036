import * as React from 'react';
import { Stack, Grid, Card, Flex, Text, Box, Image, Group } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { WELCOME_PAGE_CSS_GLASS_CARD, WELCOME_PAGE_BREAKPOINT_MEDIUM } from '../constants';
import GettingStartedStep1Visual from '../../assets/pages/home/getting-started-step-1.svg';
import GettingStartedStep2Visual from '../../assets/pages/home/getting-started-step-2.svg';
import GettingStartedStep3Visual from '../../assets/pages/home/getting-started-step-3.svg';

type Step = {
  title: string;
  content: string;
  image: string;
};

const steps: Step[] = [
  {
    title: 'Define a list of items.',
    content: 'Select items, such as genes, cell lines, or tissue samples, from our pre-defined list of datasets or upload your own dataset of interest.',
    image: GettingStartedStep1Visual,
  },
  {
    title: 'Rank, filter, select items.',
    content: 'Rank and filter the items by experimental and meta data. Add more columns on demand. Select most interesting items.',
    image: GettingStartedStep2Visual,
  },
  {
    title: 'Obtain detailed information.',
    content: 'Dive deeper into the selected entities and obtain further information or visualize selected items.',
    image: GettingStartedStep3Visual,
  },
];

function StepCard({ step, title, content, image }: { step: number; title: string; content: string; image: string }) {
  const largerThanMediumScreenSize = useMediaQuery(`(min-width: ${WELCOME_PAGE_BREAKPOINT_MEDIUM})`);

  const padding = largerThanMediumScreenSize ? 'xl' : 'md';
  return (
    <Card style={WELCOME_PAGE_CSS_GLASS_CARD} h="100%" p={0}>
      <Flex mt={padding} mb={0} ms={padding} me={0} gap="lg" flex={1}>
        <Text fz="3.7rem" fw={700} c="dvPrimary.6" mt="-0.9rem">
          {step}
        </Text>
        <Group flex={1} justify="space-between" align="flex-start">
          <Text size={`${largerThanMediumScreenSize ? 'lg' : 'md'}`} flex={1} me={padding}>
            <Text component="span" fw={500}>
              {title}
            </Text>{' '}
            {content}
          </Text>
          <Box
            style={{
              aspectRatio: '40 / 20',
              // objectFit: 'contain',
              objectPosition: 'right top',
              width: '100%',
              maxWidth: '100%',
              height: 'auto',
              alignSelf: 'flex-end',
            }}
            mt="xs"
          >
            <Image src={image} style={{ borderTopLeftRadius: 'calc(var(--paper-radius) * 2)' }} bg="linear-gradient(150deg, #337AB7 0%, #206198 100%)" />
          </Box>
        </Group>
      </Flex>
    </Card>
  );
}

export function GettingStarted() {
  return (
    <Stack w="100%" justify="space-around">
      <Grid>
        {steps.map((e, index) => (
          <Grid.Col span={{ md: 4 }} key={e.title}>
            <StepCard step={index + 1} title={e.title} content={e.content} image={e.image} />
          </Grid.Col>
        ))}
      </Grid>
    </Stack>
  );
}
